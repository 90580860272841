import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Spinner,
} from "reactstrap";
import { useNavigate, useLocation, Link } from "react-router-dom"; // Import Link from react-router-dom
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../api/config.js";
import logo from "../../assets/images/logo.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import withRouter from "../../components/Common/withRouter";

const Login = (props) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Check authentication status on component mount
  useEffect(() => {
    const storedAuthUser = localStorage.getItem("authUser");
    if (storedAuthUser) {
      const role = JSON.parse(storedAuthUser).role;
      // Only navigate to login if the user is not authenticated
      if (location.pathname === "/login") {
        if (role === "admin") {
          navigate("/dashboard");
        } else if (role === "counselor") {
          navigate("/counselor/dashboard");
        }
      }
    }
  }, [navigate, location.pathname]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${config.apiBaseUrl}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        const results = await response.json();

        if (response.status === 200) {
          localStorage.setItem("authUser", JSON.stringify(results.data));
          const role = results.data.role;
          // Navigate based on user role upon successful login
          if (role === "admin") {
            navigate("/dashboard");
          } else if (role === "counselor") {
            navigate("/counselor/dashboard");
          } else {
            localStorage.removeItem("authUser");
            setError("Invalid user role.");
            navigate("/login");
          }
        } else {
          setError(results.message || "Login failed. Please try again.");
        }
      } catch (error) {
        console.error("Login request failed", error);
        setError("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  document.title = "Login | Rectify You";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center d-block auth-logo">
                        <img src={logo} alt="Logo" height="100" />
                      </div>
                      <div className="text-center">
                        <h5 className="mb-0 mt-5">Login To Continue</h5>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error && <Alert color="danger">{error}</Alert>}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            value={validation.values.email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.email &&
                                validation.errors.email
                              )
                            }
                          />
                          <FormFeedback>{validation.errors.email}</FormFeedback>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              value={validation.values.password}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                !!(
                                  validation.touched.password &&
                                  validation.errors.password
                                )
                              }
                            />
                            <button
                              type="button"
                              className="btn btn-light shadow-none ms-0"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                            <FormFeedback>
                              {validation.errors.password}
                            </FormFeedback>
                          </div>
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <Spinner size="sm" /> : "Log In"}
                          </button>
                        </div>
                      </Form>
                      <div className="mt-3 text-center">
                        <Link
                          to="/forget-password"
                          className="text-primary fw-semibold"
                        >
                          Forget Password?
                        </Link>
                      </div>
                    </div>

                    <div className=" text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Rectify. Developed By{" "}
                        <br />
                        <a
                          href="https://mobiliya.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-underline"
                        >
                          MOBILIYA TECHNOLOGIES LLP
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
