import React, { useEffect, useState, Fragment } from 'react';
import { Card, Container, CardBody, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import TableContainer from './TableContainer'; // Import the pagination table component
import { fetchAllCompletedBookings } from '../../api/admin/Bookings'; // Import your API call

const AppointmentsHistory = () => {
  document.title = 'Rectify You';

  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const getCompletedAppointments = async () => {
      try {
        const appointments = await fetchAllCompletedBookings();
        if (appointments.length === 0) {
          setError('No completed appointments yet.');
        } else {
          const sortedAppointments = appointments.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
          setCompletedAppointments(sortedAppointments);
        }
      } catch (err) {
        setError(err.message || 'Failed to fetch appointments.');
      }
    };

    getCompletedAppointments();
  }, []);

  // Define the columns for the TableContainer component
  const columns = React.useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: 'user_first_name',
        Cell: ({ row }) => `${row.original.user_first_name} ${row.original.user_last_name}`,
      },
      {
        Header: 'Date',
        accessor: 'booking_date',
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
        },
      },
      {
        Header: 'Time',
        accessor: 'slot_time',
        Cell: ({ row }) => row.original.slot_time,
      },
      {
        Header: 'Counseling Type',
        accessor: 'problem_category',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary w-10"
            onClick={() => handleViewDetails(row.original.booking_hashid)}
          >
            View Details
          </button>
        ),
      },
    ],
    []
  );

  const handleViewDetails = (hashid) => {
    navigate(`/completed-appointments/completed-booking-details/${hashid}`);
  };

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Completed List"
            breadcrumbPage="Completed Appointments"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {error ? (
                    <div className="text-center">
                      <h5>{error}</h5>
                    </div>
                  ) : completedAppointments.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={completedAppointments}
                      isGlobalFilter={true} // Enables the global search filter
                      isAddOptions={false}
                      customPageSize={10} // Set the default page size
                      className="table align-middle"
                      isBordered={true}
                      theadClass="thead-light"
                    />
                  ) : (
                    <div className="text-center">
                      <h5>Loading completed appointments...</h5>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default AppointmentsHistory;
