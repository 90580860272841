// api/bookingsApi.js
import config from "../config";

export const fetchAllBookings = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const counselorId = JSON.parse(storedAuthUser).hashid;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(
      `${config.apiBaseUrl}/bookings?counselor_id=${counselorId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Schemas: db,
        },
      }
    );

    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
      throw new Error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

// api/bookingsApi.js

export const fetchAppointmentDetails = async (appointmentHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(
      `${config.apiBaseUrl}/bookings/details/${appointmentHashId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Schemas: db,
        },
      }
    );

    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
      throw new Error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

//suggested test add

export const SuggestedTestInsert = async (bookingDetails, testData) => {
  const storedAuthUser  = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;

  const booking_id = bookingDetails.booking_id;
  const user_id = bookingDetails.user_id;
  const user_email = bookingDetails.user_email; // Get user_email from bookingDetails

  const { testcategory_id, test_id } = testData;

  // console.log("Booking Details:", { booking_id, user_id, user_email }); // Log user_email
  // console.log("Test Data:", { testcategory_id, test_id });

  const response = await fetch(`${config.apiBaseUrl}/suggested-test-insert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      booking_id,
      user_id,
      testcategory_id,
      test_id,
      user_email, // Include user_email in the request body
    }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

// api/bookingsApi.js

export const fetchSuggestedTestsByBookingId = async (bookingId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(
      `${config.apiBaseUrl}/suggested-tests/${bookingId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Schemas: db,
        },
      }
    );

    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
      throw new Error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const deleteSuggestedTest = async (testHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;

    const response = await fetch(`${config.apiBaseUrl}/suggested-tests/${testHashId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete suggested test');
    }

    return response.json();
  } catch (error) {
    console.error('Error deleting suggested test:', error);
    throw error;
  }
};



export const updateMeetingLink = async (bookingHashId, meetingLink, userEmail, slot_date, slot_time) => {
  try {
    const storedAuthUser  = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser ).token;
    const db = JSON.parse(storedAuthUser ).db;

    const response = await fetch(`${config.apiBaseUrl}/meeting-link/${bookingHashId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
      body: JSON.stringify({ 
        meeting_link: meetingLink,
        user_email: userEmail,
        slot_date: slot_date,
        slot_time: slot_time,
      }),
    });

    if (response.ok) {
      const contentType = response.headers.get("content-type");

      // Check if response has JSON content
      if (contentType && contentType.includes("application/json")) {
        const results = await response.json();
        return results.data;
      }
      return null; // Return null if there's no content but still successful (like 204 No Content)
    } else {
      const errorResponse = await response.json();
      console.error(errorResponse.message);
      throw new Error(errorResponse.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};


// api/admin/Bookings.js

export const fetchMeetingLink = async (bookingHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(`${config.apiBaseUrl}/meeting-link/${bookingHashId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
    });

    if (response.ok) {
      const contentType = response.headers.get("content-type");

      // Check if response has JSON content
      if (contentType && contentType.includes("application/json")) {
        const results = await response.json();
        return results.data;
      }
      return null; // Return null if there's no content but still successful (like 204 No Content)
    } else {
      const errorResponse = await response.json();
      console.error(errorResponse.message);
      throw new Error(errorResponse.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

//update booking status

// api/bookingsApi.js

export const markBookingAsCompleted = async (bookingHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(`${config.apiBaseUrl}/bookings/${bookingHashId}/complete`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
      body: JSON.stringify({
        status: "completed"
      }),
    });

    if (!response.ok) {
      throw new Error('Error marking booking as completed');
    }

    return await response.json();
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};


// api call
export const fetchBookingStatus = async (bookingHashId) => {
  try {
    const storedAuthUser  = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser ).db;

    const response = await fetch(`${config.apiBaseUrl}/bookings/${bookingHashId}/status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
    });

    if (!response.ok) {
      throw new Error('Error fetching booking status');
    }

    return await response.json();
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};



// api/bookingsApi.js
export const fetchAllCompletedBookings = async () => {
  try {
    const storedAuthUser  = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const counselorId = JSON.parse(storedAuthUser ).hashid;
    const db = JSON.parse(storedAuthUser ).db;

    const response = await fetch(
      `${config.apiBaseUrl}/bookings/completed?counselor_id=${counselorId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Schemas: db,
        },
      }
    );

    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
      throw new Error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};