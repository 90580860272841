import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages

import AuthLogout from "../pages/AuthenticationInner/Logout";

// Super Admin related pages
import SuperAdminDashboard from "../superAdmin/dashboardPanel/index";
import Users from "../superAdmin/Users/Users";

import Counselor from "../superAdmin/counselor/Counselor";
import CounselorRegister from "../superAdmin/counselor/CounselorRegister";
import CounselorEdit from "../superAdmin/counselor/counselorEdit";
import CounselorDetails from '../superAdmin/counselor/CounselorDetails'
//Counselor Related Pages

import CounselorDashboard from "../counselorAdmin/dashboard/index";
import CounselorSchedule from "../counselorAdmin/schedule/index";
import CounselorAppointments from "../counselorAdmin/Appointments/appointmenstsList";
import CounselorNextAppointments from "../counselorAdmin/Appointments/UpcomingAppointmentsList";
import CounselorHistory from "../counselorAdmin/Appointments/AppointmentsHistory";
import CounselorSetting from "../counselorAdmin/setting/index";
import ChangePassword from "../counselorAdmin/setting/ChangePassword";
import CounselorProfile from "../counselorAdmin/setting/counselor-profile";
import Cancelled from "../counselorAdmin/Appointments/CancelledAppointment";
import BookingDetails from "../counselorAdmin/Appointments/UserBookingDetails";
import CompletedBookingDetails from "../counselorAdmin/Appointments/CompletedBookingDetails";

import TestResult from "../counselorAdmin/Appointments/TestResult";

import Enquiry from "../superAdmin/Enqueries/ContactInquiries";
import AdminSettings from "../superAdmin/setting/index";
import Categories from "../superAdmin/setting/Categories/index";
import CategoriesRegister from "../superAdmin/setting/Categories/CategoriesRegister";
import CategoriesEdit from "../superAdmin/setting/Categories/CategoriesEdit";
import MenuPriority from "../superAdmin/setting/Categories/SetMenuPriority";

//tests

import Tests from "../superAdmin/test/index";
import AddTest from "../superAdmin/test/testcategories/index";
import TcategoriesRegister from "../superAdmin/test/testcategories/TCategoriesRegister";
import TcategoriesEdit from "../superAdmin/test/testcategories/TCategoriesEdit";
import TestsList from "../superAdmin/test/CreateTest/index";
import CreateTest from "../superAdmin/test/CreateTest/TestRegister";
import AddQuestions from "../superAdmin/test/AddQuestions/AddQuestions";
import EditTest from "../superAdmin/test/AddQuestions/QuestionsEdit";
import EditTestName from "../superAdmin/test/CreateTest/TestEdit";

//admin profile

import AdminProfile from "../superAdmin/setting/Profile/ProfileEdit";

const userRoutes = [
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forget-password", component: <ForgetPwd /> },

  //AuthenticationInner pages

  { path: "/page-logout", component: <AuthLogout /> },
];

//Admin-routes

const SuperadminRoutes = [
  { path: "/dashboard", component: <SuperAdminDashboard /> },
  { path: "/users-list", component: <Users /> },

  { path: "/counselors", component: <Counselor /> },

  { path: "/counselors/counselor-register", component: <CounselorRegister /> },
  { path: "/counselors/counselor-edit/:hashid", component: <CounselorEdit /> },
  { path: "/counselors/counselor-details/:hashid", component: <CounselorDetails /> },

  { path: "/settings", component: <AdminSettings /> },

  { path: "/settings/categories-list", component: <Categories /> },
  { path: "/settings/categories-register", component: <CategoriesRegister /> },
  { path: "/settings/Categories-edit/:hashid", component: <CategoriesEdit /> },
  { path: "/settings/menubar-priority", component: <MenuPriority /> },


  
  { path: "/admin/enquiry", component: <Enquiry /> },

  //tests
  { path: "/tests", component: <Tests /> },
  { path: "/tests/test-categories", component: <AddTest /> },
  {
    path: "/tests/test-categories-register",
    component: <TcategoriesRegister />,
  },
  {
    path: "/tests/test-Categories-edit/:hashid",
    component: <TcategoriesEdit />,
  },
  { path: "/tests/tests-list", component: <TestsList /> },
  { path: "/tests/create-test", component: <CreateTest /> },
  { path: "/tests/add-questions", component: <AddQuestions /> },
  { path: "/tests/edit-test", component: <EditTest /> },
  { path: "/tests/edit-test-name/:test_hashid", component: <EditTestName /> },
  //admin profile

  { path: "/settings/profile", component: <AdminProfile /> },
];

const CounselorRoutes = [
  { path: "/counselor/dashboard", component: <CounselorDashboard /> },
  { path: "/counselor/schedule", component: <CounselorSchedule /> },
  {
    path: "/appointments-list",
    component: <CounselorAppointments />,
  },
  {
    path: "/counselor/upcoming-appointments",
    component: <CounselorNextAppointments />,
  },
  { path: "/counselor/settings", component: <CounselorSetting /> },
  { path: "/counselor/settings/change-password", component: <ChangePassword /> },
  { path: "/counselor/settings/counselor-profile", component: <CounselorProfile /> },
  { path: "/completed-appointments", component: <CounselorHistory /> },
  { path: "/counselor/cancelled", component: <Cancelled /> },
  {
    path: "/appointments-list/booking-details/:appointmentHashId",
    component: <BookingDetails />,
  },
  {
    path: "/completed-appointments/completed-booking-details/:appointmentHashId",
    component: <CompletedBookingDetails />,
  },

  { path: "/test-results/:test_hashid", component: <TestResult /> },
];

export { userRoutes, authRoutes, SuperadminRoutes, CounselorRoutes };
