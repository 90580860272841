import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
// import { fetchCompanyDashboardList } from "../../api/company/CompanyDashboard";
import { getAppointmentCount } from "../../api/admin/Counselor";
import { Link } from "react-router-dom";
 
const Dashboard = () => {
  document.title = "Dashboard | Rectify-you ";
  // const [activeData, setActiveData] = useState({});
  const [activeData, setActiveData] = useState({
    live_appointments: 0,
    upcoming_appointments: 0,
    completed_appointments: 0,
    total_revenue: 0, 
  });
 
 
 
  useEffect(() => {
    const fetchAppointmentCounts = async () => {
      try {
        const counselorId = JSON.parse(localStorage.getItem("authUser")).hashid;
        const appointmentData = await getAppointmentCount(counselorId);
        setActiveData(appointmentData.data);
      } catch (error) {
        console.error("Failed to fetch appointment counts:", error);
      }
    };
 
    fetchAppointmentCounts();
  }, []);
 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Dashboard"
            breadcrumbPage="Dashboard"
          />
          <Row>
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/appointments-list`} className="text-dark">
                  <CardBody>
                    <h5>Live Appointments</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.live_appointments} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/appointments-list`} className="text-dark">
                  <CardBody>
                    <h5>Upcoming Appointments</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.upcoming_appointments} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/completed-appointments`} className="text-dark">
                  <CardBody>
                    <h5>Completed Appointments</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.completed_appointments} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <CardBody>
                  <h5>Total Revenue</h5>
                  <span className="text-bold font-size-20" style={{ color: "#5156be" }}>
                    {activeData.total_revenue} 
                  </span>
                  <div className="text-muted font-size-13">Total Count</div>
                </CardBody>
              </Card>
            </Col>
           
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
 
export default Dashboard;