import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";

import {
  TimeslotInsert,
  fetchTimeSlots,
  TimeslotDelete,
} from "../../api/admin/TimeSlots";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer, toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TimeSlotTable from "./TimeSlotTable";

const ManageAvailability = () => {
  const [selectedMonth, setSelectedMonth] = useState({
    month: null,
    year: null,
  });
  const [isUpdatingSlots, setIsUpdatingSlots] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState(60);
  const [generatedTimeSlots, setGeneratedTimeSlots] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [addedSlots, setAddedSlots] = useState({});
  const [fetchedSlots, setFetchedSlots] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showOverlapModal, setShowOverlapModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [overlappingSlots, setOverlappingSlots] = useState([]);

  const bottomrightnotify = () =>
    toast("Time slots added successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const formatDateWithDay = (date) => {
    const dateObject = new Date(date);
    const day = dateObject.toLocaleDateString("en-GB", {
      weekday: "short",
      timeZone: "UTC",
    });
    const dayNumber = String(dateObject.getUTCDate()).padStart(2, "0");
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0");
    const year = dateObject.getUTCFullYear();
    return `${day} ${dayNumber}-${month}-${year}`;
  };

  const loadSlotsForMonth = async () => {
    if (selectedMonth.month === null || selectedMonth.year === null) {
      return;
    }

    setLoading(true);
    try {
      const response = await fetchTimeSlots(selectedMonth);
      if (response.status && response.timeSlots) {
        const slots = response.timeSlots;
        setFetchedSlots((prevSlots) => ({
          ...prevSlots,
          [`${selectedMonth.year}-${selectedMonth.month}`]: slots,
        }));
      } else {
        alert("No time slots found for the selected month.");
      }
    } catch (error) {
      console.error("Failed to fetch time slots:", error);
      alert("Error occurred while fetching time slots.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSlotsForMonth();
  }, [selectedMonth]);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleDaySelection = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handleGenerateTimeSlots = () => {
    if (!startTime || !endTime) {
      setAlertMessage("Please select a start and end time.");
      setShowAlertModal(true);
      return;
    }

    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    if (start >= end) {
      setAlertMessage(
        "The end time must be after the start time. Please ensure that the end time you selected is later than the start time. For example, if you set the start time to 10:00 AM, the end time should be set to a time like 10:30 AM or later."
      );
      setShowAlertModal(true);
      return;
    }

    const slots = [];
    let current = new Date(start);

    while (current < end) {
      const next = new Date(current);
      next.setMinutes(current.getMinutes() + duration);

      if (next > end) break;

      slots.push(
        `${current.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} - ${next.toLocaleTimeString(
          [],
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        )}`
      );
      current = next;
    }

    setGeneratedTimeSlots(slots);
    setSelectedTimeSlots([]);
  };

  const handleTimeSlotSelection = (slot) => {
    setSelectedTimeSlots((prevSlots) =>
      prevSlots.includes(slot)
        ? prevSlots.filter((s) => s !== slot)
        : [...prevSlots, slot]
    );
  };

  const handleAddSlots = async () => {

    //date validation to restrict user from add sltos for old

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); 

// Check if the selected month and year are in the past
if (
  selectedMonth.year < currentYear ||
  (selectedMonth.year === currentYear && selectedMonth.month < currentMonth)
) {
  setAlertMessage("You cannot add slots for a past month.");
  setShowAlertModal(true);
  return;
}

   
    if (
      selectedMonth.month === null || // Explicitly check for null
      selectedMonth.year === null || // Explicitly check for null
      selectedDays.length === 0 ||
      selectedTimeSlots.length === 0
    ) {
      if (selectedMonth.month === null || selectedMonth.year === null) {
        setAlertMessage("Please select a month and year.");
      } else if (selectedDays.length === 0) {
        setAlertMessage("Please select at least one day of the week.");
      } else if (selectedTimeSlots.length === 0) {
        setAlertMessage("Please select at least one time slot.");
      }
      setShowAlertModal(true);
      return;
    }

    // Fetch existing time slots before adding new ones
    const response = await fetchTimeSlots(selectedMonth);
    const existingSlots = response.timeSlots; // This is an object

    // Prepare the new slots to be inserted
    const newSlots = selectedTimeSlots; // Assuming selectedTimeSlots is an array of strings

    // Check for overlapping slots
    const overlapping = checkForOverlappingSlots(newSlots, existingSlots);

    if (overlapping.length > 0) {
      setOverlappingSlots(overlapping);
      setShowOverlapModal(true);
    } else {
      // No overlaps, proceed to add slots
      setLoading(true);
      setIsUpdatingSlots(true);
      try {
        await TimeslotInsert({
          selectedMonth,
          selectedDays,
          selectedTimeSlots,
          daysOfWeek,
        });
        // bottomrightnotify();
        // loadSlotsForMonth();

        // Update the fetchedSlots state directly
        const monthKey = `${selectedMonth.year}-${selectedMonth.month}`;
        const newSlotsData = newSlots.map((slot) => ({
          slotTime: slot,
        }));

        setFetchedSlots((prevSlots) => {
          const updatedSlots = { ...prevSlots };
          if (!updatedSlots[monthKey]) {
            updatedSlots[monthKey] = {};
          }
          selectedDays.forEach((day) => {
            if (!updatedSlots[monthKey][day]) {
              updatedSlots[monthKey][day] = [];
            }
            updatedSlots[monthKey][day] = [
              ...updatedSlots[monthKey][day],
              ...newSlotsData,
            ];
          });
          return updatedSlots;
        });

        const updatedResponse = await fetchTimeSlots(selectedMonth);
        setFetchedSlots(updatedResponse.timeSlots);

        bottomrightnotify();
        await loadSlotsForMonth();
      } catch (error) {
        console.error("Failed to insert time slots:", error);
        alert("Error occurred while inserting time slots.");
      } finally {
        setLoading(false);
      }
    }
  };

  const checkForOverlappingSlots = (newSlots, existingSlots) => {
    const overlapping = new Set(); // Use a Set to avoid duplicates

    // Convert time string to a comparable format (timestamp)
    const convertToTimestamp = (time) => {
      const [hourMinute, period] = time.split(" ");
      let [hour, minute] = hourMinute.split(":").map(Number);
      if (period === "pm" && hour < 12) hour += 12; // Convert to 24-hour format
      if (period === "am" && hour === 12) hour = 0; // Handle 12 AM case
      return hour * 60 + minute; // Convert to total minutes
    };

    // Check against existing slots
    Object.keys(existingSlots).forEach((date) => {
      existingSlots[date].forEach((existingSlot) => {
        const [existingStartTime, existingEndTime] = existingSlot.slotTime
          .split(" - ")
          .map((time) => convertToTimestamp(time));

        // Check for overlaps with each new slot
        newSlots.forEach((newSlot) => {
          const [newStartTime, newEndTime] = newSlot
            .split(" - ")
            .map((time) => convertToTimestamp(time));

          // Check for overlap
          if (
            newStartTime < existingEndTime &&
            newEndTime > existingStartTime
          ) {
            overlapping.add(existingSlot.slotTime); // Add to Set to avoid duplicates
          }
        });
      });
    });

    return Array.from(overlapping); // Convert Set back to Array
  };
  const handleModalResponse = (response) => {
    setShowAlertModal(false);
    if (response === "proceed") {
      setLoading(true);
      try {
        TimeslotInsert({
          selectedMonth,
          selectedDays,
          selectedTimeSlots,
          daysOfWeek,
        });
        bottomrightnotify();
        // loadSlotsForMonth();
      } catch (error) {
        console.error("Failed to insert time slots:", error);
        alert("Error occurred while inserting time slots.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOverlapModalResponse = async (response) => {
    setShowOverlapModal(false);
    if (response === "proceed") {
      setLoading(true);
      try {
        await TimeslotInsert({
          selectedMonth,
          selectedDays,
          selectedTimeSlots,
          daysOfWeek,
        });
        bottomrightnotify();
        await loadSlotsForMonth(); // Wait for the insertion to finish before loading slots
      } catch (error) {
        console.error("Failed to insert time slots:", error);
        alert("Error occurred while inserting time slots.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteSlot = async (date, slot) => {
    const monthKey = `${selectedMonth.year}-${selectedMonth.month}`;

    const timeSlotToBeDeleted = fetchedSlots[monthKey][date].find(
      (timeSlot) => timeSlot.slotTime === slot
    );

    if (!timeSlotToBeDeleted) {
      console.error("Time slot not found");
      return;
    }

    const hashid = timeSlotToBeDeleted.hashid;

    await TimeslotDelete(hashid);

    setFetchedSlots((prevSlots) => {
      const updatedSlots = { ...prevSlots };

      if (updatedSlots[monthKey] && updatedSlots[monthKey][date]) {
        updatedSlots[monthKey][date] = updatedSlots[monthKey][date].filter(
          (s) => s.hashid !== hashid
        );

        if (updatedSlots[monthKey][date].length === 0) {
          delete updatedSlots[monthKey][date];
        }
        if (Object.keys(updatedSlots[monthKey]).length === 0) {
          delete updatedSlots[monthKey];
        }
      }

      return updatedSlots;
    });
  };

  const renderSlotsForSelectedMonth = () => {
    const monthKey = `${selectedMonth.year}-${selectedMonth.month}`;

    if (
      !fetchedSlots[monthKey] ||
      Object.keys(fetchedSlots[monthKey]).length === 0
    ) {
      return <p>No slots added for this month yet.</p>;
    }

    return (
      <table className="table table-bordered mt-4">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Day & Date</th>
            <th>Slots</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(fetchedSlots[monthKey]).map((date) => (
            <tr key={date}>
              <td>{formatDateWithDay(date)}</td>
              <td>
                <div className="d-flex flex-wrap">
                  {fetchedSlots[monthKey][date].map((slot, index) => (
                    <div key={index} className="m-1">
                      <span className="badge bg-secondary p-2">
                        {slot.slotTime}
                        <button
                          className="btn btn-danger btn-sm ms-2"
                          onClick={() => handleDeleteSlot(date, slot.slotTime)}
                        >
                          X
                        </button>
                      </span>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div
      className="container-fluid"
      style={{ marginTop: "10rem", paddingBottom: "10rem" }}
    >
      <Breadcrumbs
        title="Page"
        breadcrumbItem="Time Slots"
        breadcrumbPage="Manage Time Slots Availability"
      />
      <div className="row mt-3 align-items-start">
        <div className="col-md-2">
          <label htmlFor="month" className="form-label">
            Select Month*
          </label>
          <div className="d-flex">
  <select
    className="form-select"
    value={selectedMonth.month ?? ""}
    onChange={(e) =>
      setSelectedMonth({
        ...selectedMonth,
        month: Number(e.target.value),
      })
    }
  >
    <option value="" disabled>
      Month
    </option>
    {Array.from({ length: 12 }, (_, index) => (
      <option key={index} value={index}>
        {new Date(0, index).toLocaleString("default", { month: "long" })}
      </option>
    ))}
  </select>
</div>


          <label htmlFor="year" className="form-label mt-2">Select Year*</label>
<div className="d-flex">
  <select
    className="form-select"
    value={selectedMonth.year ?? ""}
    onChange={(e) =>
      setSelectedMonth({
        ...selectedMonth,
        year: Number(e.target.value),
      })
    }
  >
    <option value="" disabled>Year</option>
    {(() => {
      const currentYear = new Date().getFullYear();
      // Generate years: previous year to next 5 years
      return Array.from({ length: 2 }, (_, i) => currentYear  + i).map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ));
    })()}
  </select>
</div>

        </div>

        <div className="col-md-5">
          <label className="form-label">Select Days of the Week*</label>
          <div className="d-flex flex-row mb-3">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="form-check mb-2 me-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={day}
                  checked={selectedDays.includes(day)}
                  onChange={() => handleDaySelection(day)}
                />
                <label className="form-check-label" htmlFor={day}>
                  {day}
                </label>
              </div>
            ))}
          </div>

          <div className="d-flex align-items-center mb-3">
            <div className="me-3">
              <label className="form-label">Start Time*</label>
              <input
                type="time"
                className="form-control"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
            <div className="me-3">
              <label className="form-label">End Time*</label>
              <input
                type="time"
                className="form-control"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="duration" className="form-label">
                Select Duration*
              </label>
              <select
                id="duration"
                className="form-select"
                value={duration}
                onChange={(e) => setDuration(Number(e.target.value))}
              >
                <option value={15}>15 minutes</option>
                <option value={30}>30 minutes</option>
                <option value={60}>1 hour</option>
                <option value={90}>1 hour 30 minutes</option>
              </select>
            </div>
          </div>

          <div className="d-flex me-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={handleGenerateTimeSlots}
            >
              Generate Time Slots
            </button>
          </div>
        </div>

        {generatedTimeSlots.length > 0 && (
          <div className="col-md-5">
            <label className="form-label">Select Time Slots *</label>

            <div className="d-flex align-items-center mb-2">
              <button
                className="btn btn-warning btn-sm me-2"
                onClick={
                  () =>
                    selectedTimeSlots.length === generatedTimeSlots.length
                      ? setSelectedTimeSlots([]) // Deselect all
                      : setSelectedTimeSlots(generatedTimeSlots) // Select all
                }
              >
                {selectedTimeSlots.length === generatedTimeSlots.length
                  ? "Deselect All"
                  : "Select All"}
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleAddSlots}
                disabled={loading}
              >
                {loading ? "Adding..." : "Add Slots"}
              </button>
            </div>

            <div className="d-flex flex-wrap">
              {generatedTimeSlots.map((slot, index) => (
                <button
                  key={index}
                  className={`btn m-1 ${
                    selectedTimeSlots.includes(slot)
                      ? "btn-success"
                      : "btn-outline-secondary"
                  }`}
                  onClick={() => handleTimeSlotSelection(slot)}
                >
                  {slot}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="row mt-4">
        <div className="col-md-12">
          <h4>
            Time Slots Scheduled for{" "}
            {selectedMonth.year && selectedMonth.month !== null
              ? `${new Date(selectedMonth.year, selectedMonth.month).toLocaleString("default", { month: "long" })} - ${selectedMonth.year}`
              : "N/A"}{" "}
          </h4>

          <TimeSlotTable
            fetchedSlots={fetchedSlots}
            selectedMonth={selectedMonth}
            formatDateWithDay={formatDateWithDay}
            handleDeleteSlot={handleDeleteSlot}
          />
        </div>
      </div>
      <Modal isOpen={showAlertModal} toggle={() => setShowAlertModal(false)}>
        <ModalHeader toggle={() => setShowAlertModal(false)}>Alert</ModalHeader>
        <ModalBody>
          <p className="text-danger">{alertMessage}</p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => setShowAlertModal(false)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showOverlapModal}
        toggle={() => setShowOverlapModal(false)}
      >
        <ModalHeader toggle={() => setShowOverlapModal(false)}>
          Overlapping Slots
        </ModalHeader>
        <ModalBody>
          <p className="text-danger">
            {" "}
            We found existing time slots that may overlap with your new
            selection.
          </p>
          <ul>
            {overlappingSlots.map((slot, index) => (
              <li key={index}>{slot}</li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => setShowOverlapModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleOverlapModalResponse("proceed")}
          >
            Proceed
          </button>
        </ModalFooter>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default ManageAvailability;
