import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardFooter
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { fetchUserTestResult } from "../../api/admin/Tests";

const TestResult = () => {
  const { test_hashid } = useParams(); 
  const [testName, setTestName] = useState(""); 
  const [userAnswers, setUserAnswers] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [modal, setModal] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        setLoading(true);
        const response = await fetchUserTestResult(test_hashid);

        if (response.status !== undefined) {
          // If response has status, handle it accordingly
          if (response.status) {
            setTestName(response.data.length > 0 ? response.data[0].test_name : ""); 
            setUserAnswers(response.data || []); 
          } else {
            // Display message if there are no results
            setError(response.message); // Set the error message
            setModal(true); // Open the modal
          }
        } else {
          // Handle unexpected responses
          setError("Failed to fetch test result.");
        }
      } catch (err) {
        setError("Failed to fetch test result.");
      } finally {
        setLoading(false);
      }
    };

    if (test_hashid) {
      fetchAnswers();
    } else {
      setError("Invalid test identifier.");
      setLoading(false);
    }
  }, [test_hashid]);

  const toggleModal = () => {
    setModal(!modal); 
    if (modal) {
      setError(null); // Clear error when closing modal
    }
  };

  if (loading) {
    return (
      <Container className="text-center my-5">
        <Spinner color="primary" /> Loading results...
      </Container>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <CardBody>
          <Row>
            <Col lg="8">
              <Card className="shadow-sm">
                <CardBody>
                  <h2 className="text-center mb-4">{testName || "Test Result"}</h2>
                  {userAnswers.length > 0 ? (
                    userAnswers.map((answer, index) => (
                      <Card key={answer.question_hashid} className="mb-3">
                        <CardBody>
                          <Row>
                            <Col sm="2" className="text-muted">
                              <strong>Q{index + 1}:</strong>
                            </Col>
                            <Col sm="10">
                              <h6>{answer.question_text}</h6>
                              <p>
                                <strong>Answer:</strong> {answer.option_text}
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))
                  ) : (
                    <p className="text-center">No answers found for this test.</p>
                  )}
                </CardBody>
                <CardFooter className="text-right">
                  <Button
                    type="button"
                    color="primary"
                    className="mr-2"
                    onClick={() => navigate(-1)} 
                  >
                    Back
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </CardBody>

        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>No Test Submitted</ModalHeader>
          <ModalBody>
            {error || "It appears the test has not been submitted by the user."}
          </ModalBody>
          <ModalFooter>
          <Button
                    type="button"
                    color="primary"
                    toggle={toggleModal}
                    className="mr-2"
                    onClick={() => navigate(-1)} 
                  >
                    Back
                  </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default TestResult;
