import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useNavigate, useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetchTestDetails, updateTestName } from "../../../api/admin/Tests";

const UpdateTest = () => {
  const { test_hashid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTestDetails = async () => {
      const response = await fetchTestDetails(test_hashid);
      if (response.status) {
        validation.setValues({
          testName: response.data.test_name,
          description: response.data.description,
        });
      } else {
        toast.error("Failed to fetch test details.");
      }
      setLoading(false);
    };
    getTestDetails();
  }, [test_hashid]);

  // Success toast notification (bottom-right)
  const bottomrightnotify = () =>
    toast("Test updated successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  // Form validation with Formik & Yup
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      testName: "",
      description: "",
    },
    validationSchema: Yup.object({
      testName: Yup.string().required("Please enter the test name"),
      description: Yup.string().required("Please enter a description"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await updateTestName(test_hashid, values);
        if (response.ok) {
          bottomrightnotify(); // Trigger toast notification
          setTimeout(() => {
            navigate(-1); // Navigate back after 5 seconds
          }, 2000);
        } else {
          const errorData = await response.json();
          toast.error(errorData.message || "Failed to update test");
        }
      } catch (error) {
        toast.error("An error occurred while updating the test.");
        console.error("Error during test update:", error);
      }
    },
  });

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Edit Test Name"
            breadcrumbPage="Edit Test Name"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <Label className="form-label">Test Name *</Label>
                        <Input
                          name="testName"
                          type="text"
                          placeholder="Enter the test name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.testName}
                          invalid={
                            validation.touched.testName &&
                            validation.errors.testName
                          }
                        />
                        {validation.touched.testName &&
                          validation.errors.testName && (
                            <FormFeedback>
                              {validation.errors.testName}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={8}>
                        <Label className="form-label">Description *</Label>
                        <Input
                          name="description"
                          type="text"
                          placeholder="Enter a brief description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                          }
                        />
                        {validation.touched.description &&
                          validation.errors.description && (
                            <FormFeedback>
                              {validation.errors.description}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex gap-2">
                      <Button type="submit" color="primary">
                        Update Test
                      </Button>
                      <Link to="/tests/tests-list">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer /> {/* ToastContainer to display toasts */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateTest;
