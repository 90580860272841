// App.js
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  userRoutes,
  authRoutes,
  SuperadminRoutes,
 
  CounselorRoutes
  
} from "./routes/allRoutes";

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/";
import AdminLayout from "./components/AdminLayout";
import CounselorLayout from "./components/CounselorLayout";
import NonAuthLayout from "./components/NonAuthLayout";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import SuperAdminAuthLayout from "./components/SuperAdminAuthLayout";
import CounselorAuthLayout from './components/CounselorLayout';

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

const App = (props) => {
  let navigate = useNavigate();
  const [Layout, setLayout] = useState(HorizontalLayout); // Default layout

  useEffect(() => {
    const storedAuthUser = localStorage.getItem("authUser");
    if (storedAuthUser) {
      const decoded = jwtDecode(JSON.parse(storedAuthUser).token);
      // console.log("Decoded JWT:", decoded);
      if (new Date() < new Date(decoded.exp * 1000)) {
        // console.log("User Role:", JSON.parse(storedAuthUser).role);

        switch (JSON.parse(storedAuthUser).role) {
          case "admin":
            setLayout(AdminLayout);
            break;
          // case "vendor":
          //   setLayout(VendorLayout);
          //   break;
          case "counselor":
            setLayout(CounselorLayout);
            break;
          default:
            setLayout(HorizontalLayout);
            break;
        }
      } else {
        localStorage.removeItem("authUser");
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {SuperadminRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <SuperAdminAuthLayout>
                <Layout>{route.component}</Layout>
              </SuperAdminAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {/* {VendorRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <VendorAuthLayout>
                <Layout>{route.component}</Layout>
              </VendorAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))} */}
        {CounselorRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <CounselorAuthLayout>
                <Layout>{route.component}</Layout>
              </CounselorAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
