import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  CardFooter,
  Button,
  Modal
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS

import TableContainer from "./components/TableContainer";
import { CounselorName, EmailAddress, MobileNo, StartDate, EndDate } from "./components/invoicelistCol"; 
import moment from 'moment';
import { Link } from "react-router-dom";
import { fetchAllCounselors, CounselorDelete } from "../../api/admin/Counselor";  

const Counselor = () => {
  document.title = "Counselor's List | Rectify You ";
  const [counselor, setCounselor] = useState([]); 
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedCounselorId, setSelectedCounselorId] = useState(null);

  const toggleConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const handleDelete = (hashid) => {
    setSelectedCounselorId(hashid);
    toggleConfirmationModal();  // Open confirmation modal
  };

  useEffect(() => {
    const getCounselor = async () => {
      try {
        const counselorData = await fetchAllCounselors();  
        setCounselor(counselorData);
      } catch (error) {
        console.error('Failed to fetch Counselor:', error);
      }
    };
    getCounselor();
  }, []);

  const confirmDelete = async () => {
    if (selectedCounselorId) {
      try {
        const response = await CounselorDelete(selectedCounselorId);
        if (response.ok) {
          const updatedCounselors = counselor.filter(c => c.hashid !== selectedCounselorId);
          setCounselor(updatedCounselors);
          bottomrightnotify(); // Use the toast notification instead of alert
        } else {
          alert("Failed to delete counselor.");
        }
      } catch (error) {
        console.error("Delete error:", error);
        alert("An error occurred while deleting the counselor.");
      }
      toggleConfirmationModal(); 
    }
  };
  
  const bottomrightnotify = () => {
    toast("Counselor Deleted successfully!", { 
      position: "bottom-right", 
      hideProgressBar: true, 
      className: 'bg-success text-white' 
    });
  };
  

  const columns = useMemo(
    () => [  
      {
        Header: "Counselor Name",
        accessor: row => {
          const { first_name, middle_name, last_name } = row;
          return middle_name ? `${first_name} ${middle_name} ${last_name}` : `${first_name} ${last_name}`;
        },
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <CounselorName {...cellProps} />,
      },
      {
        Header: "Email Address",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <EmailAddress {...cellProps} />,
      },
      {
        Header: "Mobile No",
        accessor: "mobile",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <MobileNo {...cellProps} />,
      },
      {
        Header: "Start Date",
        accessor: (row) => `${row.start_date ? moment(row.start_date).format('DD MMM YYYY') : 'N/A'}`,
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <StartDate {...cellProps} />,
      },
      {
        Header: "End Date",
        accessor: (row) => `${row.end_date ? moment(row.end_date).format('DD MMM YYYY') : 'N/A'}`,
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <EndDate {...cellProps} />,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (counselor) => {
          const status = counselor.row.original.status;
          const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
          return (
            <div className={"badge font-size-12 " + (status === 'active' ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger')}>
              {capitalizedStatus}
            </div>
          );
        },
      },        
      {
        Header: "Action",
        Cell: (categories) => {
          return (
            <UncontrolledDropdown>
            <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
              <i className="bx bx-dots-horizontal-rounded"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem tag={Link} to={`/counselors/Counselor-edit/${categories.row.original.hashid}`} className="text-dark">
                Edit Counselor
              </DropdownItem>
              <DropdownItem tag={Link} to={`/counselors/Counselor-details/${categories.row.original.hashid}`} className="text-dark">
                View Details
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          
          );
        },
      },
    ],
    [counselor] // Add counselor as a dependency to keep columns up to date
  );

  return (
    <React.Fragment>
     <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Page" breadcrumbItem="Counselor" breadcrumbPage="Counselor List" />
          <Row>
            <Col lg="12">
              <Row>
                <div className="col-sm d-flex align-items-start justify-content-end mb-3">
                  <Link to="/counselors/counselor-register" className="btn btn-primary btn-rounded btn-sm waves-effect waves-light">
                    <i className="bx "></i> Add Counselor
                  </Link>
                </div>
              </Row>
              <Card>
                <CardBody>                                 
                  <TableContainer columns={columns} data={counselor} isGlobalFilter={true} customPageSize={10} />
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                    <Link to="/dashboard">
                      <Button type="button" color="warning">
                        Back
                      </Button>
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Confirmation Modal */}
      <Modal isOpen={confirmationModalOpen} toggle={toggleConfirmationModal}>
        <div className="modal-header">
          <h5 className="modal-title">Confirm Deletion</h5>
          <button type="button" className="btn-close" onClick={toggleConfirmationModal}></button>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete this counselor?</p>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={confirmDelete}>Yes, Delete</Button>
          <Button color="secondary" onClick={toggleConfirmationModal}>Cancel</Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Counselor;
