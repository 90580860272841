import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Step 1

import {
  fetchAppointmentDetails,
  fetchSuggestedTestsByBookingId,
  updateMeetingLink,
  fetchMeetingLink,
  markBookingAsCompleted,
  fetchBookingStatus,
} from "../../api/admin/Bookings";
import { deleteSuggestedTest } from "../../api/admin/Bookings";

import {
  fetchAllTestCategories,
  fetchTestsByCategory,
} from "../../api/admin/Tests";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Alert,
  Table,
  Input,
  Modal,
  CardFooter
} from "reactstrap";
import { Slide, ToastContainer, toast } from "react-toastify";

import SuggestTestModal from "./SuggestTestModal";

const AppointmentsDetails = () => {
  const navigate = useNavigate();

  const { appointmentHashId } = useParams();
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [testCategories, setTestCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [suggestedTests, setSuggestedTests] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [meetingLink, setMeetingLink] = useState("");
  const [isLinkSaved, setIsLinkSaved] = useState(false);
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false); // For marking appointment completion
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const bottomrightnotify = () =>
    toast("Marked as completed Successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  useEffect(() => {
    const getAppointmentDetails = async () => {
      try {
        const details = await fetchAppointmentDetails(appointmentHashId);
        setAppointmentDetails(details);
      } catch (err) {
        console.error(err);
        setFetchError("Error fetching appointment details.");
      } finally {
        setLoading(false);
      }
    };

    const loadTestCategories = async () => {
      try {
        const response = await fetchAllTestCategories();
        if (response.status) {
          setTestCategories(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchSuggestedTests = async () => {
      try {
        const suggestedTests =
          await fetchSuggestedTestsByBookingId(appointmentHashId);
        setSuggestedTests(suggestedTests);
      } catch (err) {
        console.error(err);
        setFetchError("Error fetching suggested tests.");
      }
    };

    const fetchMeetingLinkDetails = async () => {
      try {
        const meetingLinkDetails = await fetchMeetingLink(appointmentHashId);
        if (meetingLinkDetails) {
          setMeetingLink(meetingLinkDetails);
          setIsLinkSaved(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchBookingStatusDetails = async () => {
      try {
        const response = await fetchBookingStatus(appointmentHashId);
        if (response.success) {
          setIsCompleted(response.data.status === "completed");
        }
      } catch (err) {
        console.error(err);
      }
    };

    getAppointmentDetails();
    loadTestCategories();
    fetchSuggestedTests();
    fetchMeetingLinkDetails();
    fetchBookingStatusDetails();
  }, [appointmentHashId]);

  const handleSuggestTest = async (test) => {
    // console.log("Adding suggested test:", test); 
    setModalOpen(false);

    try {
      // Fetch the updated suggested tests from the backend after adding a new test
      const updatedSuggestedTests =
        await fetchSuggestedTestsByBookingId(appointmentHashId);
      setSuggestedTests(updatedSuggestedTests);
    } catch (err) {
      console.error("Error fetching updated suggested tests:", err);
      setFetchError("Error fetching updated suggested tests.");
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleDeleteTest = async (testHashId) => {
    // console.log("Deleting test with hash ID:", testHashId); 
    try {
      await deleteSuggestedTest(testHashId);
      setSuggestedTests((prevTests) =>
        prevTests.filter((test) => test.test_hashid !== testHashId)
      );
      alert("Test deleted Successfully");
    } catch (error) {
      console.error("Error deleting test:", error);
      alert("Failed to delete test");
    }
  };

  const handleSaveLink = async () => {
    try {
      const response = await updateMeetingLink(appointmentHashId, meetingLink);

      if (response !== null) {
        setIsLinkSaved(true); // Save the state that link is saved
        setIsEditingLink(false); // Immediately switch back to non-edit mode
        // console.log("Meeting link updated Successfully:", meetingLink);
      } else {
        setIsLinkSaved(true);
        setIsEditingLink(false);
        // console.log("Meeting link updated with no content returned.");
      }

      // Fetch the updated meeting link (Optional if you already have the updated state)
      const updatedMeetingLink = await fetchMeetingLink(appointmentHashId);
      setMeetingLink(updatedMeetingLink); // Update the link immediately after saving
    } catch (error) {
      console.error("Error updating the meeting link:", error);
      alert("Failed to update the meeting link. Please try again.");
    }
  };

  const handleEditLink = () => {
    setIsEditingLink(true);
  };

  const handleCancelEdit = () => {
    setIsEditingLink(false);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(meetingLink);
    alert("Link copied to clipboard");
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner color="primary" /> Loading...
      </Container>
    );
  }

  if (!appointmentDetails) {
    return (
      <Container className="mt-5">
        <Alert color="warning">No appointment details found.</Alert>
      </Container>
    );
  }

  const user_id = appointmentDetails.user_id;
  const booking_id = appointmentDetails.booking_hashid;

  const toggleConfirmationModal = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  const confirmMarkAsCompleted = async () => {
    toggleConfirmationModal(); // Close the modal first
    try {
      await markBookingAsCompleted(appointmentHashId); // Call the API to mark as completed
      setIsCompleted(true); // Update the state to reflect completion
      bottomrightnotify(); // Show the success notification
    } catch (err) {
      console.error("Error marking appointment as completed:", err);
      toast.error("Failed to mark appointment as completed."); // Show error notification
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody className="p-0">
              <Row className="mb-4">
                <Col md={12}>
                  <div className="section">
                    <h3 className="mb-1 text h5 p-3">Client Details</h3>
                    <hr className="m-0" />
                    <Row className="p-4">
                      <Col md={6}>
                        <p>
                          <strong>Name:</strong>{" "}
                          {`${appointmentDetails.user_first_name} ${appointmentDetails.user_last_name}`}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          {appointmentDetails.user_email}
                        </p>
                        <p>
                          <strong>Mobile:</strong>{" "}
                          {appointmentDetails.user_mobile}
                        </p>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                    
                  </div>
                  <hr className="m-0" />

                  <div className="section">
                    <h3 className="mb-1 text h5 p-3">
                      Appointment Information
                    </h3>
                    <hr className="m-0" />
                    <Row className="p-4">
                      <Col md={6}>
                        <p>
                          <strong>Slot Date:</strong>{" "}
                          {new Date(
                            appointmentDetails.slot_date
                          ).toLocaleDateString()}
                        </p>
                        <p>
                          <strong>Slot Time:</strong>{" "}
                          {appointmentDetails.slot_time}
                        </p>
                        <p>
                          <strong>Problem Category:</strong>{" "}
                          {appointmentDetails.problem_category}
                        </p>
                        <p>
                          <strong>Language:</strong>{" "}
                          {appointmentDetails.language}
                        </p>
                        <p>
                          <strong>Message:</strong> {appointmentDetails.message}
                        </p>
                        <p>
                          <strong>Fees:</strong> ₹{appointmentDetails.fees}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <hr className="m-0" />
                 {/* Meeting Section */}
<div className="section">
  <h3 className="mb-1 text h5 p-3">Meeting Link</h3>
  <hr className="m-0" />
  <Row className="p-4">
    <Col md={12}>
      {isLinkSaved ? (
        <div>
          <p>
            <strong>Meeting Link:</strong> {meetingLink}
          </p>
        </div>
      ) : (
        <p className="text-danger">Meeting link not sent.</p>
      )}
    </Col>
  </Row>
</div>
                  <hr className="m-0" />

                  <hr className="m-0" />

                  <div className="section">
                    <h3 className="mb-1 text h5 p-3">Suggested Tests</h3>
                    <hr className="m-0" />
                    <Row className="p-4">
                      <Col md={12}>
                       
                        <div className="mt-3">
                          {suggestedTests.length > 0 ? (
                            <Table responsive bordered>
                              <thead>
                                <tr>
                                  <th>Category</th>
                                  <th>Test</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {suggestedTests.map((test, index) => (
                                  <tr key={index}>
                                    <td>{test.category_name}</td>
                                    <td>{test.test_name}</td>
                                    <td>
                                    <Button
                            color="secondary"
                            onClick={() => navigate(`/test-results/${test.test_hashid}`)} 
                          >
                            View Result
                          </Button>
                                      {/* <Button
                                        color="danger"
                                        className="me-1 m-1"
                                        onClick={() =>
                                          handleDeleteTest(test.test_hashid)
                                        }
                                      >
                                        Delete
                                      </Button> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <p>No suggested tests yet.</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="section">
                  
                  <hr className="m-0" />

  <h3 className="mb-1 text h5 p-3">
   Completion Status
  </h3>
  <hr className="m-0" />                    <hr className="m-0" />
                    <Row className="p-4 mb-0">
                      <Col md={12}>
                        {!isCompleted ? (
                          <Button
                            color="success"
                            onClick={toggleConfirmationModal}
                          >
                            Mark as Completed
                          </Button>
                        ) : (
                          <p  className="text-success fs-5">This appointment has been marked as completed.</p>
                        )}
                      </Col>
                    </Row>

                    {/* Confirmation Modal */}
                    <Modal
                      isOpen={confirmationModalOpen}
                      toggle={toggleConfirmationModal}
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title">Confirm Completion</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={toggleConfirmationModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Are you sure you want to mark this appointment as
                          completed?
                        </p>
                      </div>
                      <div className="modal-footer">
                        <Button color="danger" onClick={confirmMarkAsCompleted}>
                          Yes, Complete
                        </Button>
                        <Button
                          color="secondary"
                          onClick={toggleConfirmationModal}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-right d-flex justify-content-end">
              <Button type="button" color="primary" className="mr-2" onClick={() => window.history.back()}>
                Back
              </Button>
            </CardFooter>
          </Card>
        </Container>
      </div>

      <SuggestTestModal
        isOpen={modalOpen}
        toggle={toggleModal}
        testCategories={testCategories}
        onSubmit={handleSuggestTest}
        bookingDetails={{ user_id, booking_id }}
      />
    </React.Fragment>
  );
};

export default AppointmentsDetails;
