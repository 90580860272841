import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Admin");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setRole(obj.role || "");
      setEmail(obj.email || "");
  
      // Set username based on role
      if (obj.role === "admin") {
        // Replace "null null" or falsy values with an empty string
        setUsername(obj.fullname && obj.fullname.trim() !== "null null" ? obj.fullname : ""); 
      } else {
        setUsername(obj.username && obj.username.trim() !== "null null" ? obj.username : ""); 
      }
    }
  }, [props.success]);
  

  // Logout function to clear authUser and navigate to login
  const handleLogout = () => {
    localStorage.removeItem("authUser"); // Clear the user data
    localStorage.clear(); // Optional: clear all local storage data
    navigate("/login"); // Redirect to login page
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <button className="dropdown-item">
            <span>
              {role === "admin" ? (
                <h6 className="mb-0" style={{ color: "#6c757d" }}>
                  Admin
                </h6>
              ) : role === "counselor" ? (
                <h6 className="mb-0" style={{ color: "#6c757d" }}>
                  Counselor
                </h6>
              ) : null}
            </span>
          </button>
          <div className="dropdown-divider" />

          <div className="px-3 py-2">
            <h6 className="mb-0">{username}</h6>
            <small className="text-muted">{email}</small>
          </div>

          <div className="dropdown-divider" />
          <button onClick={handleLogout} className="dropdown-item">
            <i className="mdi mdi-logout font-size-16 align-middle me-1"></i>
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
