import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardFooter,
  Button,
  Modal
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TableContainer from "./components/TableContainer";
import { UserName, UserEmail, UserMobile } from "./components/invoicelistCol"; 
import { fetchAllUsers } from "../../api/admin/Users";  

const Users = () => {
  document.title = "Users List | Rectify You";
  const [users, setUsers] = useState([]); 

  useEffect(() => {
    const getUsers = async () => {
      try {
        const usersData = await fetchAllUsers();  
        setUsers(usersData);
      } catch (error) {
        console.error('Failed to fetch Users:', error);
      }
    };
    getUsers();
  }, []);

  const columns = useMemo(
    () => [  
      {
        Header: "User Name",
        accessor: row => {
          const { first_name, last_name } = row;
          return `${first_name} ${last_name}`;
        },
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <UserName {...cellProps} />,
      },
      {
        Header: "Email ID",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => <UserEmail {...cellProps} />,
      },
      {
        Header: "Mobile No",
        accessor: "mobile",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          const { value } = cellProps;
          return value ? <UserMobile {...cellProps} /> : <span>Logged in via Google</span>;
        },
      }
      
    ],
    [] // No need to include users as a dependency since we are not modifying columns
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Page" breadcrumbItem="Users List" breadcrumbPage="Users List" />
          <Row>
            <Col lg="12">
             
              <Card>
                <CardBody>
                  <TableContainer columns={columns} data={users} isGlobalFilter={true} customPageSize={10} />
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                  <Link to="/dashboard">
                      <Button type="button" color="warning">
                        Back
                      </Button>
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
