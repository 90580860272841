import React, { useEffect, useState, Fragment } from 'react';
import { Card, Container, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './TableContainer'; // Import the pagination table component

const UpcomingAppointmentsList = () => {
  document.title = 'Upcoming Appointments | Rectify You - Counselor';

  const [appointments] = useState([
    {
      id: 1,
      user: 'User A',
      date: '2024-10-05',
      time: '1:00 PM - 2:00 PM',
      therapy: 'Cognitive Behavioral Therapy',
    },
    {
      id: 2,
      user: 'User B',
      date: '2024-10-06',
      time: '3:00 PM - 4:00 PM',
      therapy: 'Mindfulness Therapy',
    },
    {
      id: 3,
      user: 'User C',
      date: '2024-10-01',
      time: '10:00 AM - 11:00 AM',
      therapy: 'Family Therapy',
    },
    // Add more data if needed
  ]);

  const [upcomingAppointments, setUpcomingAppointments] = useState([]);

  useEffect(() => {
    const today = new Date();
    const filteredAppointments = appointments.filter((appointment) => {
      const appointmentDate = new Date(appointment.date);
      return appointmentDate >= today;
    });
    setUpcomingAppointments(filteredAppointments);
  }, [appointments]);

  // Define the columns for the TableContainer component
  const columns = React.useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Time',
        accessor: 'time',
      },
      {
        Header: 'Therapy',
        accessor: 'therapy',
      },
    ],
    []
  );

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Page" breadcrumbItem="Counselor" breadcrumbPage="" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h1 className="card-title fs-3">Upcoming Appointments</h1>
                </CardHeader>
                <CardBody>
                  {upcomingAppointments.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={upcomingAppointments}
                      isGlobalFilter={true} // Enables the global search filter
                      isAddOptions={false}
                      customPageSize={10} // Set the default page size
                      className="table align-middle"
                      isBordered={true}
                      theadClass="thead-light"
                    />
                  ) : (
                    <p className="text-center">No upcoming appointments.</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default UpcomingAppointmentsList;
