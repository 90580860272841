import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classname from "classnames";
import withRouter from "../Common/withRouter";

// Import Icons
import FeatherIcon from "feather-icons-react";

// i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const Navbar = (props) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  

  const isActive = (path) => activePath.startsWith(path);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className={classname("nav-link dropdown-toggle arrow-none", {
                      active: isActive("/dashboard"),
                    })}
                    to="/dashboard"
                  >
                    <FeatherIcon icon="home" />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className={classname("nav-link dropdown-toggle arrow-none", {
                      active: isActive("/users-list"),
                    })}
                    to="/users-list"
                  >
                    <FeatherIcon icon="users" />
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className={classname("nav-link dropdown-toggle arrow-none", {
                      active: isActive("/counselors"),
                    })}
                    to="/counselors"
                  >
                    <FeatherIcon icon="user-plus" />
                    <span>{props.t("Counselors")}</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className={classname("nav-link dropdown-toggle arrow-none", {
                      active: isActive("/tests"),
                    })}
                    to="/tests"
                  >
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Tests")}</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className={classname("nav-link dropdown-toggle arrow-none", {
                      active: isActive("/admin/enquiry"),
                    })}
                    to="/admin/enquiry"
                  >
                    <FeatherIcon icon="help-circle" />
                    <span>{props.t("Enquiry")}</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className={classname("nav-link dropdown-toggle arrow-none", {
                      active: isActive("/settings"),
                    })}
                    to="/settings"
                  >
                    <FeatherIcon icon="settings" />
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(Navbar)));
