import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  fetchAllTestCategories,
  fetchTestsByCategory,
  addQuestionWithOptions,
} from "../../../api/admin/Tests";

const TestRegister = () => {
  document.title = "Add Questions | Admin Panel";

  const [testCategories, setTestCategories] = useState([]);
  const [availableTests, setAvailableTests] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTestHashId, setSelectedTestHashId] = useState("");
  const [options, setOptions] = useState([""]);

  const notify = () =>
    toast("Question added successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
      icon: false,
    });

  // Fetch test categories on component mount
  useEffect(() => {
    const fetchData = async () => {
      const categoryResponse = await fetchAllTestCategories();
      if (categoryResponse.status) {
        setTestCategories(categoryResponse.data);
      } else {
        console.error(categoryResponse.message);
      }
    };
    fetchData();
  }, []);

  // Fetch available tests when a category is selected
  const handleCategoryChange = async (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);
    setAvailableTests([]); // Clear previous test names
    setSelectedTestHashId(""); // Clear previous selected test
  
    if (selected) {
      const testResponse = await fetchTestsByCategory(selected);
      if (testResponse.status && testResponse.data.length > 0) {
        setAvailableTests(testResponse.data);
      } else {
        toast.error("No tests found for the selected category. Please add test name before proceeding.", {
          position: "bottom-right",
          hideProgressBar: true,
          className: "bg-danger text-white",
          icon: false,
          autoClose: 7000,
        });
      }
    }
  };
  

  // Form validation with Formik & Yup
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      testCategory: "",
      testName: "",
      question: "",
      options: [],
    },
    validationSchema: Yup.object({
      // Uncomment these validations if required
      // testCategory: Yup.string().required("Please select a category"),
      // testName: Yup.string().required("Please select a test name"),
      // question: Yup.string().required("Please enter the question"),
      // options: Yup.array().of(Yup.string().required("Option cannot be empty")),
    }),
    onSubmit: async (values) => {
      const questionData = {
        question: values.question,
        options: options.filter((opt) => opt.trim() !== ""),
      };

      // Validation to ensure a test hash ID is selected
      if (!selectedTestHashId) {
        toast.error("Please select a test name before submitting.", {
          position: "bottom-right",
          hideProgressBar: true,
          className: "bg-danger text-white",
          icon: false,
        });
        // console.log("Test Hash ID is not selected.");
        return;
      }

      const response = await addQuestionWithOptions(
        selectedTestHashId,
        questionData.question,
        questionData.options
      );

      if (response.status) {
        notify();

        validation.setFieldValue("question", ""); // Clear question input
        setOptions([""]); // Reset options to initial state with one empty option
      } else {
        toast.error("Failed to Register question.", {
          position: "bottom-right",
          hideProgressBar: true,
          className: "bg-danger text-white",
          icon: false,
        });
      }
    },
  });

  // Handle adding more options dynamically
  const addOptionField = () => {
    setOptions([...options, ""]);
  };

  // Handle option value changes
  const handleOptionChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    setOptions(newOptions);
    // console.log(`Option ${index + 1} updated:`, event.target.value);
  };

  const handleTestNameChange = (e) => {
    const selectedTestName = e.target.value;
  
    const selectedTest = availableTests.find(
      (test) => test.test_name === selectedTestName
    );
  
    if (selectedTest) {
      setSelectedTestHashId(selectedTest.hashid);
    } else {
      setSelectedTestHashId("");
      toast.error("Please select a valid test name.", {
        position: "bottom-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        icon: false,
      });
    }
  
    validation.handleChange(e);
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs
            title="Page"
            breadcrumbItem="Categories"
            breadcrumbPage="Add Test Categories"
          /> */}

          <Breadcrumbs
            title="Page"
            breadcrumbItem="Add Questions"
            breadcrumbPage="Add Questions In Test"
          />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <Label className="form-label">Test Category *</Label>
                        <Input
                          name="testCategory"
                          id="testCategory"
                          type="select"
                          className="form-control"
                          onChange={(e) => {
                            validation.handleChange(e);
                            handleCategoryChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.testCategory}
                          invalid={
                            validation.touched.testCategory &&
                            validation.errors.testCategory
                          }
                        >
                          <option value="">Select Category</option>
                          {testCategories.map((category) => (
                            <option
                              key={category.hashid}
                              value={category.hashid}
                            >
                              {category.category_name}
                            </option>
                          ))}
                        </Input>

                        {validation.touched.testCategory &&
                        validation.errors.testCategory ? (
                          <FormFeedback type="invalid">
                            {validation.errors.testCategory}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Test Name *</Label>
                        <Input
                          name="testName"
                          id="testName"
                          type="select"
                          className="form-control"
                          onChange={handleTestNameChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.testName}
                          disabled={availableTests.length === 0}
                          invalid={
                            validation.touched.testName &&
                            validation.errors.testName
                          }
                          
                        >
                          <option value="">Select Test Name</option>
                          {availableTests.map((test) => (
                            <option key={test.hashid} value={test.test_name}>
                              {" "}
                              {/* Use hashid as key */}
                              {test.test_name}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.testName &&
                        validation.errors.testName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.testName}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={8}>
                        <Label className="form-label">Question *</Label>
                        <Input
                          name="question"
                          id="question"
                          type="text"
                          className="form-control"
                          placeholder="Enter your question"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.question}
                          invalid={
                            validation.touched.question &&
                            validation.errors.question
                          }
                        />
                        {validation.touched.question &&
                        validation.errors.question ? (
                          <FormFeedback type="invalid">
                            {validation.errors.question}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={8}>
                        <Label className="form-label">Options *</Label>
                        {options.map((option, index) => (
                          <Input
                            key={index}
                            type="text"
                            value={option}
                            onChange={(e) => handleOptionChange(index, e)}
                            placeholder={`Option ${index + 1}`}
                            className="mb-2"
                          />
                        ))}
                        <Button
                          type="button"
                          color="primary"
                          onClick={addOptionField}
                        >
                          Add Option
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Link to="/tests">
                      <Button type="button" color="warning" className="ms-2">
                        Back
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
        <ToastContainer
          transition={Slide}
          autoClose={2000}
          hideProgressBar={true}
        />
      </div>
    </React.Fragment>
  );
};

export default TestRegister;
