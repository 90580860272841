import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAdminInfo } from "../../../../src/api/admin/Enquiries";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Spinner,
  Alert,
} from "reactstrap";

const ViewAdminDetails = () => {
  const { adminId } = useParams(); // Assuming you are passing adminId in the URL
  const [adminDetails, setAdminDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAdminDetails = async () => {
      try {
        const details = await fetchAdminInfo(adminId); // Fetch admin info by ID
        setAdminDetails(details);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getAdminDetails();
  }, [adminId]);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner color="primary" /> Loading...
      </Container>
    ); // Display a loading spinner
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert color="danger">Error: {error}</Alert> {/* Display any errors */}
      </Container>
    );
  }

  if (!adminDetails) {
    return (
      <Container className="mt-5">
        <Alert color="warning">No admin details found.</Alert>{" "}
        {/* Handle the case of no data */}
      </Container>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Admin Profile"
            breadcrumbPage="Admin Profile"
          />
          <Card>
            <CardBody className="p-0">
              <Row className="mb-4">
                <Col md={12}>
                  <div className="section">
                    <h3 className="mb-1 text h5 p-3">Admin Details</h3>
                    <hr className="m-0" />
                    <Row className="p-4">
                      <Col md={6}>
                        <p>
                          <strong>Name:</strong> {adminDetails.first_name}{" "}
                          {adminDetails.middle_name
                            ? `${adminDetails.middle_name} `
                            : ""}
                          {adminDetails.last_name}
                        </p>

                        <p>
                          <strong>Mobile:</strong> {adminDetails.mobile}
                        </p>
                        <p>
                          <strong>Email:</strong> {adminDetails.email}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              <Button
                type="button"
                color="primary"
                className="mr-2"
                onClick={() => window.history.back()}
              >
                Back
              </Button>
            </CardFooter>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewAdminDetails;
