import React from 'react';
import PropTypes from 'prop-types'
import withRouter from "../components/Common/withRouter"

const SuperAdminAuthLayout = (props) => {
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}

SuperAdminAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default withRouter(SuperAdminAuthLayout);
