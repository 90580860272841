import React, { useEffect, useMemo, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  CardFooter,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "./components/TableContainer";
import { CategoryName } from "./components/invoicelistCol";
import { Link } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import CategoriesRegister from "./TCategoriesRegister";
import {
  fetchAllTestCategories,
  deleteTestCategory,
} from "../../../api/admin/Tests";
import { toast } from "react-toastify"; // Import toast

const Category = () => {
  document.title = "Test Categories List | Rectify - Company";
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [Category, setCategory] = useState([]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const CategoryData = await fetchAllTestCategories(); // Fetch categories
        setCategory(CategoryData.data); // Directly set the data array to the state
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };
    getCategory();
  }, []);

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data); // Set the selected order for deletion
  };

  const bottomrightDeleteNotify = () =>
    toast("Category deleted successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const handleDeleteInvoice = async () => {
    if (order?.hashid) {
      try {
        await deleteTestCategory(order.hashid); // Call delete API
        setCategory((prev) =>
          prev.filter((cat) => cat.hashid !== order.hashid)
        ); // Remove from state
        setDeleteModal(false);
        bottomrightDeleteNotify(); // Show the success notification
      } catch (error) {
        console.error("Failed to delete category:", error);
      }
    }
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Categories Name",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CategoryName {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (categories) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                tag="button"
                className="btn btn-light btn-sm"
                caret
              >
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  tag={Link}
                  to={`/tests/test-Categories-edit/${categories.row.original.hashid}`}
                >
                  Edit
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => onDeleteData(categories.row.original)} // Trigger delete modal
                >
                  Delete
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice} // Handle the actual delete
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <CategoriesRegister />
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Category"
            breadcrumbPage="Test Categories List"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={Category}
                    isGlobalFilter={true}
                    customPageSize={10}
                  />
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                    <Link to="/tests">
                      <Button type="button" color="warning">
                        Back
                      </Button>
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Category;
