import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "./components/TableContainer"; // Import your TableContainer component
import { fetchContactInquiries, deleteInquiry } from "../../api/admin/Enquiries";
import moment from "moment";
import { Slide, ToastContainer, toast } from "react-toastify";

const ContactInquiries = () => {
  document.title = "Enquiries | Rectify You";
  const [inquiries, setInquiries] = useState([]);
  const [modal, setModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [selectedInquiryId, setSelectedInquiryId] = useState(null); // To store the ID of the inquiry to delete

  useEffect(() => {
    const getInquiries = async () => {
      try {
        const data = await fetchContactInquiries();
        setInquiries(data || []);
      } catch (error) {
        toast.error("Error fetching inquiries.");
      }
    };
    getInquiries();
  }, []);

  const bottomrightnotify = () =>
    toast("Enquiry deleted successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const handleDelete = (hashid) => {
    setSelectedInquiryId(hashid); // Set the ID of the inquiry to delete
    setConfirmationModalOpen(true); // Open the confirmation modal
  };

  const confirmDelete = async () => {
    if (selectedInquiryId) {
      try {
        const response = await deleteInquiry(selectedInquiryId);
        if (response.status) {
          bottomrightnotify();
          setInquiries(inquiries.filter((inquiry) => inquiry.hashid !== selectedInquiryId));
        } else {
          toast.error("Failed to delete inquiry.");
        }
      } catch (error) {
        toast.error("Error deleting inquiry.");
      } finally {
        setConfirmationModalOpen(false); // Close the confirmation modal
        setSelectedInquiryId(null); // Reset the selected inquiry ID
      }
    }
  };

  const toggleModal = () => setModal(!modal);
  const toggleConfirmationModal = () => setConfirmationModalOpen(!confirmationModalOpen);

  const columns = useMemo(() => [
    {
      Header: "Date",
      accessor: (row) => moment(row.created_at).format("DD MMM YYYY"),
      disableFilters: true,
    },

   
    {
      Header: "Name",
      accessor: "name",
      filterable: true,
      disableFilters: true,
    },
    {
      Header: "Email",
      accessor: "email",
      filterable: true,
      disableFilters: true,
    },
    {
      Header: "Enquiry Type",
      accessor: "type",
      filterable: true,
      disableFilters: true,
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: ({ row }) => (
        <span
          onClick={() => {
            setSelectedMessage(row.original.message);
            toggleModal();
          }}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {row.original.message.length > 50 ? `${row.original.message.substring(0, 50)}...` : row.original.message}
        </span>
      ),
      disableFilters: true,
    },
   
    {
      Header: "Action",
      Cell: ({ row }) => (
        <UncontrolledDropdown>
          <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
            <i className="bx bx-dots-horizontal-rounded"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => handleDelete(row.original.hashid)} className="text-danger">
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ], [inquiries]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Page" breadcrumbItem="Enquiry List" breadcrumbPage="Enquiry List" />
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader>
                  <h1 className="card-title fs-3">Contact Inquiries</h1>
                </CardHeader> */}
                <CardBody>
                  <TableContainer columns={columns} data={inquiries} isGlobalFilter={true} customPageSize={10} />
                </CardBody>
                <CardFooter>
                <div className="d-flex justify-content-end">

               
                  <Link to="/dashboard" className="btn btn-warning ">
                    Back
                  </Link>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Inquiry Message</ModalHeader>
            <ModalBody>
              <p>{selectedMessage}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={confirmationModalOpen} toggle={toggleConfirmationModal}>
            <ModalHeader toggle={toggleConfirmationModal}>Confirm Deletion</ModalHeader>
            <ModalBody>
              <p>Are you sure you want to delete this inquiry?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={confirmDelete}>Yes, Delete</Button>
              <Button color="secondary" onClick={toggleConfirmationModal}>Cancel</Button>
            </ModalFooter>
          </Modal>

          <ToastContainer transition={Slide} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactInquiries;
