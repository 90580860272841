import config from "../config";

export const fetchAllUsers = async () => {
    try {
      const storedAuthUser = localStorage.getItem("authUser");
      const token = JSON.parse(storedAuthUser).token;
      const response = await fetch(`${config.apiBaseUrl}/admin/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const results = await response.json();
      if (response.status === 200) {
        return results.data;
      } else {
        console.error(results.message);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  };