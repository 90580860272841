import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { fetchTestNameByCategoryOnly } from "../../api/admin/Tests";
import { SuggestedTestInsert } from "../../api/admin/Bookings";

const SuggestTestModal = ({
  isOpen,
  toggle,
  testCategories,
  onSubmit,
  bookingDetails,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [error, setError] = useState(null);
  const [availableTests, setAvailableTests] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCategoryChange = async (e) => {
    const categoryHashid = e.target.value;
    setSelectedCategory(categoryHashid);

    if (!categoryHashid) {
      setAvailableTests([]);
      return;
    }

    try {
      const response = await fetchTestNameByCategoryOnly(categoryHashid);
      if (response.status) {
        setAvailableTests(response.data);
      } else {
        setAvailableTests([]);
        setError("No tests available for this category.");
      }
    } catch (err) {
      setError(err.message);
      setAvailableTests([]);
    }
  };

  const handleSubmit = async () => {
    if (!selectedTest) {
      setError("Please select a test.");
      return;
    }
  
    try {
      setLoading(true);
      const testData = {
        testcategory_id: selectedCategory,
        test_id: selectedTest,
        category_name: testCategories.find(
          (cat) => cat.hashid === selectedCategory
        )?.category_name, 
        test_name: availableTests.find((test) => test.hashid === selectedTest)
          ?.test_name, 
user_email: bookingDetails.userEmail      };
  
      const response = await SuggestedTestInsert(bookingDetails, testData);
  
      setLoading(false);
      toggle();
      onSubmit(testData); 
    } catch (error) {
      console.error("Submission error:", error);
      setError("Failed to submit test suggestion. Please try again.");
      setLoading(false);
    }
  };
  

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Suggest a Test</ModalHeader>
      <ModalBody>
        {error && <Alert color="danger">{error}</Alert>}
        <Label for="testCategory">Test Category</Label>
        <Input
          type="select"
          id="testCategory"
          onChange={handleCategoryChange}
          value={selectedCategory}
        >
          <option value="">Select Category</option>
          {testCategories.map((category) => (
            <option key={category.hashid} value={category.hashid}>
              {category.category_name}
            </option>
          ))}
        </Input>

        <Label for="testName" className="mt-3">
          Test Name
        </Label>
        <Input
          type="select"
          id="testName"
          onChange={(e) => setSelectedTest(e.target.value)}
          disabled={availableTests.length === 0}
          value={selectedTest}
        >
          <option value="">Select Test Name</option>
          {availableTests.map((test) => (
            <option key={test.hashid} value={test.hashid}>
              {test.test_name}
            </option>
          ))}
        </Input>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </Button>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SuggestTestModal;
