import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getCounselorInfo,
  CounselorProfileUpdateAdmin,
} from "../../api/admin/Counselor"; // Assuming you have these API methods

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from 'moment';


const CounselorEdit = () => {
  document.title = "Edit Counselor | Rectify You - Vendor";
  const navigate = useNavigate();
  const [counselor, setCounselor] = useState({});
  const location = useLocation();
  const hashid = location.pathname.split("/").pop();

  // Fetch counselor details
  useEffect(() => {
    const fetchCounselorInfo = async () => {
      try {
        const response = await getCounselorInfo(hashid);
        setCounselor(response.data[0]); // Assuming response format as shown in the example
      } catch (error) {
        console.error("Failed to fetch counselor data:", error);
      }
    };
    fetchCounselorInfo();
  }, [hashid]);

  // Toast notification
  const bottomrightnotify = (message, type = "success") => {
    toast(message, {
      position: "bottom-right",
      hideProgressBar: true,
      className:
        type === "success" ? "bg-success text-white" : "bg-danger text-white",
    });
  };

  // Formik validation and submission
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: counselor.first_name || "",
      middle_name: counselor.middle_name || "",
      last_name: counselor.last_name || "",
      mobile: counselor.mobile || "",
      email: counselor.email || "",
      password: counselor.password || "",
      status: counselor.status || "",
      start_date: counselor.start_date || "",
      end_date: counselor.end_date || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      email: Yup.string()
        .email("Must be a valid email")
        .required("Please Enter Your Email"),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Mobile Number is not valid")
        .required("Please Enter Your Mobile Number"),
      status: Yup.string().required("Please Select Status"),
    }),
    onSubmit: async (values) => {
      try {

        const formattedValues = {
          ...values,
          start_date: moment(values.start_date).format('YYYY-MM-DD'),
          end_date: moment(values.end_date).format('YYYY-MM-DD'),
        };

        const response = await CounselorProfileUpdateAdmin(formattedValues, hashid);
        if (response.status === 200) {
          bottomrightnotify("Counselor updated successfully!");
          setTimeout(() => navigate("/counselors"), 2000);
        } else {
          bottomrightnotify(
            response.message ||
              "This email or mobile number is already registered with another user.",
            "error"
          );
          console.error("Update failed", response.message);
        }
      } catch (error) {
        bottomrightnotify(
          "An error occurred during update: " + error.message,
          "error"
        );
        console.error("An error occurred during update:", error.message);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Edit Counselor"
            breadcrumbPage="Edit Counselor"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">First Name *</Label>
                        <Input
                          name="first_name"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            !!validation.errors.first_name
                          }
                        />
                        {validation.touched.first_name &&
                          validation.errors.first_name && (
                            <FormFeedback>
                              {validation.errors.first_name}
                            </FormFeedback>
                          )}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Middle Name</Label>
                        <Input
                          name="middle_name"
                          type="text"
                          className="form-control"
                          placeholder="Middle Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.middle_name || ""}
                        />
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Last Name *</Label>
                        <Input
                          name="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            !!validation.errors.last_name
                          }
                        />
                        {validation.touched.last_name &&
                          validation.errors.last_name && (
                            <FormFeedback>
                              {validation.errors.last_name}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Mobile Number *</Label>
                        <Input
                          name="mobile"
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ""}
                          invalid={
                            validation.touched.mobile &&
                            !!validation.errors.mobile
                          }
                        />
                        {validation.touched.mobile &&
                          validation.errors.mobile && (
                            <FormFeedback>
                              {validation.errors.mobile}
                            </FormFeedback>
                          )}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Email *</Label>
                        <Input
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                            !!validation.errors.email
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <FormFeedback>
                              {validation.errors.email}
                            </FormFeedback>
                          )}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Password *</Label>
                        <Input
                          name="password"
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            !!validation.errors.password
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password && (
                            <FormFeedback>
                              {validation.errors.password}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Start Date</Label>
                        <Flatpickr
                          name="start_date"
                          className={`form-control ${validation.touched.start_date && validation.errors.start_date ? "is-invalid" : ""}`}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          onChange={(date) =>
                            validation.setFieldValue("start_date", date[0])
                          }
                          value={validation.values.start_date || ""}
                        />
                        {validation.touched.start_date &&
                          validation.errors.start_date && (
                            <FormFeedback>
                              {validation.errors.start_date}
                            </FormFeedback>
                          )}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">End Date</Label>
                        <Flatpickr
                          name="end_date"
                          className={`form-control ${validation.touched.end_date && validation.errors.end_date ? "is-invalid" : ""}`}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          onChange={(date) =>
                            validation.setFieldValue("end_date", date[0])
                          }
                          value={validation.values.end_date || ""}
                        />
                        {validation.touched.end_date &&
                          validation.errors.end_date && (
                            <FormFeedback>
                              {validation.errors.end_date}
                            </FormFeedback>
                          )}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Status *</Label>
                        <Input
                          name="status"
                          type="select"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status || ""}
                        >
                          <option value="">Select Status</option>
                          <option value="active">Active</option>
                          <option value="deactive">Deactive</option>
                        </Input>
                        {validation.touched.status &&
                          validation.errors.status && (
                            <FormFeedback>
                              {validation.errors.status}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardFooter>
                    <Button type="submit" color="primary">
                      Update
                    </Button>
                    <Link to="/counselors" className="btn btn-warning mx-2">
                      Back
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CounselorEdit;
