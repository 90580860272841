import React, { useState } from "react";
import { Container, Alert } from "reactstrap";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CategoriesInsert } from "../../../api/admin/Counselor";
import "react-toastify/dist/ReactToastify.css";

const CategoriesRegister = () => {
  // document.title = "Register Categories | Rectify You - Company";

  const [Categories, InsertCategories] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const bottomrightnotify = () =>
    toast("Categories Registered Successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category_name: "",
      status: "",
    },
    validationSchema: Yup.object({
      category_name: Yup.string().required("Please Enter Categories Name"),
      status: Yup.string()
        .oneOf(["active", "deactive"], "Invalid Status")
        .required("Please Enter Status"),
    }),
    onSubmit: async (values) => {
      try {
        const CategoriesData = await CategoriesInsert(values);
        const data = await CategoriesData.json();
        if (CategoriesData.status === 200) {
          bottomrightnotify();
          setTimeout(() => {
            navigate("/settings"); 
            setTimeout(() => {
              navigate("/settings/categories-list"); 
            }, 100);
          }, 2000); 
          
          
        } else {
          console.error("Insertion failed", data.message);
          setError(CategoriesData.message);
          toast.error("Counselor Category Already Exists", {
            position: "bottom-right",

            hideProgressBar: false,
          });
        }
        InsertCategories(CategoriesData);
      } catch (error) {
        console.error("An error occurred", error.message);
        setError(error.message);
        toast.error("Counselor Category Already Exists", {
          position: "bottom-right",

          hideProgressBar: false,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-contents ">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="RegisterCategories"
            breadcrumbPage="Register Counselor Categories"
          />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={8}>
                        <Label className="form-Label">Categories Name *</Label>
                        <Input
                          name="category_name"
                          id="category_name"
                          type="text"
                          className="form-control"
                          placeholder="Categories Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category_name || ""}
                          invalid={
                            validation.touched.category_name &&
                            validation.errors.category_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.category_name &&
                        validation.errors.category_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.category_name}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label className="form-Label">Status *</Label>
                        <Input
                          type="select"
                          name="status"
                          id="status"
                          className="form-control"
                          placeholder="Status"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status}
                          invalid={
                            validation.touched.status &&
                            validation.errors.status
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="active">Active</option>
                          <option value="deactive">Deactive</option>
                        </Input>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-xs btn btn>Submit "
                      >
                        Submit{" "}
                      </Button>
                      <Link to="/settings/Categories-list">
                        {/* <Button type="button" color="warning">
                          Back
                        </Button> */}
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoriesRegister;
