import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  CardFooter,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "./components/TableContainer";
import { CategoriesName ,OrderPriority } from "./components/invoicelistCol";
import DeleteModal from "./DeleteModal";
import { Link } from "react-router-dom";
import {
  fetchAllCategoriesList,
  CounselorCategoryDelete,
} from "../../../api/admin/Counselor";

import CategoriesRegister from "./CategoriesRegister";

const Categories = () => {
  document.title = "Categories List | Rectify You - Company";
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetchAllCategoriesList();
        if (response && response.status) {
          setCategories(response.data);
        } else {
          console.error(
            "Failed to fetch categories:",
            response.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Failed to fetch categories:", error.message);
      }
    };
    getCategories();
  }, []);

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = async () => {
    if (order.hashid) {
      try {
        await CounselorCategoryDelete(order.hashid);

        const updatedCategories = categories.filter(
          (cat) => cat.hashid !== order.hashid
        );
        setCategories(updatedCategories);

        toast.success("Category deleted successfully!", {
          position: "bottom-right",
        });
      } catch (error) {
        console.error("Error deleting category:", error);
        toast.error(
          "Failed to delete category: " + (error.message || "Unknown error"),
          {
            position: "bottom-right",
          }
        );
      }
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Categories Name",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CategoriesName {...cellProps} />;
        },
      },
      {
        Header: "Menu Priority",
        accessor: "order_priority",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <OrderPriority {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (categories) => {
          const status = categories.row.original.status;
          const capitalizedStatus =
            status.charAt(0).toUpperCase() + status.slice(1);
          return (
            <div
              className={
                "badge font-size-12 " +
                (status === "active"
                  ? "bg-success-subtle text-success"
                  : "bg-danger-subtle text-danger")
              }
            >
              {capitalizedStatus}
            </div>
          );
        },
      },
      {
        Header: "Action",
        Cell: (categories) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                tag="button"
                className="btn btn-light btn-sm"
                caret
              >
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  tag={Link}
                  to={`/settings/Categories-edit/${categories.row.original.hashid}`}
                >
                  Edit
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => onDeleteData(categories.row.original)}
                >
                  Delete
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [categories]
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <CategoriesRegister />

        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Counselor Categories List"
            breadcrumbPage="Counselor Categories List"
            
            
          />
          

          <h4 className="m-0"></h4>
          <Row>
          <div className="col-sm d-flex align-items-start justify-content-end mb-3">
                  <Link to="/settings/menubar-priority" className="btn btn-primary btn-rounded btn-sm waves-effect waves-light">
                    <i className="bx "></i> Set Menu Priority
                  </Link>
                </div>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={categories}
                    isGlobalFilter={true}
                    customPageSize={10}
                  />
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                    <Link to="/settings">
                      <Button type="button" color="warning">
                        Back
                      </Button>
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Categories;
