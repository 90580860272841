import config from "../config";

// fetchAllTestCategories

export const TestCategoriesInsert = async (values) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const hashid = JSON.parse(storedAuthUser).hashid;
  const response = await fetch(
    `${config.apiBaseUrl}/test/testcategories-insert`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        category_name: values.category_name,
        status: values.status,
      }),
    }
  );
  return response;
};


//api/admin/Tests.js

export const getTestCategoryById = async (hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const response = await fetch(
    `${config.apiBaseUrl}/test/get-testcategories/${hashid}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return response;
};


// api/admin/Tests.js

export const deleteTestCategory = async (hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const response = await fetch(`${config.apiBaseUrl}/test/testcategories-delete/${hashid}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete the test category.");
  }

  return response.json();
};


//api/admin/Tests.js

export const updateTestCategory = async (hashid, values) => {
  // console.log("hash id in info ", hashid);
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const response = await fetch(
    `${config.apiBaseUrl}/test/testcategories-update/${hashid}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        category_name: values.category_name,
      
      }),
    }
  );
  return response;
};



export const fetchAllTestCategories = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(
      `${config.apiBaseUrl}/test/get-testcategories`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};



export const fetchAllTestCategoriesOnly = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(
      `${config.apiBaseUrl}/test/get-testcategories-only`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};

export const TestNameInsert = async (values, category_hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;

  const response = await fetch(`${config.apiBaseUrl}/test/testname-insert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "category-hashid": category_hashid,
    },
    body: JSON.stringify({
      test_name: values.testName,
      description: values.description || "",
      // status:values.status || "",
    }),
  });

  return response;
};

export const fetchAllTestList = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/test/get-testlist`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};

export const fetchTestsByCategory = async (categoryHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(
      `${config.apiBaseUrl}/test/testby-category/${categoryHashId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};





export const fetchTestNameByCategoryOnly= async (categoryHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(
      `${config.apiBaseUrl}/test/testnamesby-categoryonly/${categoryHashId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};

//for add questions and options
export const addQuestionWithOptions = async (
  testHashId,
  questionText,
  optionsArray
) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser)?.token;

    if (!token) {
      console.error("No authentication token found.");
      return { status: false, message: "Authentication token is required." };
    }

    if (
      !testHashId ||
      !questionText ||
      !Array.isArray(optionsArray) ||
      optionsArray.length === 0
    ) {
      console.error("Invalid input parameters.");
      return {
        status: false,
        message: "Test hash ID, question text, and options are required.",
      };
    }

    const response = await fetch(`${config.apiBaseUrl}/test/add-question`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        test_hashid: testHashId,
        question_text: questionText,
        options: optionsArray,
      }),
    });

    const result = await response.json();

    if (response.ok) {
      return { status: true, data: result.data };
    } else {
      console.error("Error:", result.message);
      return {
        status: false,
        message: result.message || "Unknown error occurred.",
      };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return {
      status: false,
      message: error.message || "An error occurred while adding the question.",
    };
  }
};




// api call
export const fetchQuestionsByTest = async (testHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser)?.token;

    if (!token) {
      console.error("No authentication token found.");
      return { status: false, message: "Authentication token is required." };
    }

    if (!testHashId) {
      console.error("Test hash ID is required.");
      return { status: false, message: "Test hash ID is required." };
    }

    const response = await fetch(`${config.apiBaseUrl}/test/fetch-questions/${testHashId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (response.ok) {
      return { status: true, data: result.data };
    } else {
      console.error("Error:", result.message);
      return {
        status: false,
        message: result.message || "Unknown error occurred.",
      };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return {
      status: false,
      message: error.message || "An error occurred while fetching questions.",
    };
  }
};




// api call
export const updateQuestionWithOptions = async (questionHashId, questionText, optionsArray) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser)?.token;

    if (!token) {
      console.error("No authentication token found.");
      return { status: false, message: "Authentication token is required." };
    }

    if (!questionHashId || !questionText || !Array.isArray(optionsArray) || optionsArray.length === 0) {
      console.error("Invalid input parameters.");
      return { status: false, message: "Question hash ID, question text, and options are required." };
    }

    const response = await fetch(`${config.apiBaseUrl}/test/update-question`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        question_hashid: questionHashId,
        question_text: questionText,
        options: optionsArray,
      }),
    });

    const result = await response.json();

    if (response.ok) {
      return { status: true, data: result.data };
    } else {
      console.error("Error:", result.message);
      return {
        status: false,
        message: result.message || "Unknown error occurred.",
      };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return {
      status: false,
      message: error.message || "An error occurred while updating the question.",
    };
  }
};



//for delete question in test

export const deleteQuestion = async (questionHashId) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser)?.token;

    if (!token) {
      console.error("No authentication token found.");
      return { status: false, message: "Authentication token is required." };
    }

    if (!questionHashId) {
      console.error("Question hash ID is required.");
      return { status: false, message: "Question hash ID is required." };
    }

    const response = await fetch(`${config.apiBaseUrl}/test/delete-question/${questionHashId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (response.ok) {
      return { status: true, message: "Question deleted Successfully." };
    } else {
      console.error("Error:", result.message);
      return { status: false, message: result.message || "Error occurred while deleting question." };
    }
  } catch (error) {
    console.error("There was a problem with the delete operation:", error);
    return { status: false, message: error.message || "An error occurred while deleting the question." };
  }
};




// api/admin/Tests.js
// Fetch Test Details
export const fetchTestDetails = async (test_hashid) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/test/get-test/${test_hashid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};

// Update Test Name
export const updateTestName = async (test_hashid, values) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;

  const response = await fetch(`${config.apiBaseUrl}/test/testname-update/${test_hashid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      test_name: values.testName,
      description: values.description || "",
    }),
  });

  return response;
};



// api/admin/Tests.js

export const deleteTest = async (test_hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;

  const response = await fetch(`${config.apiBaseUrl}/test/delete/${test_hashid}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete test');
  }

  return response.json(); // Return response data
};



export const fetchUserTestResult = async (test_hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser)?.token; // Ensure token is available

  const response = await fetch(`${config.apiBaseUrl}/test-result/${test_hashid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 204) {
    // Handle No Content response
    return { status: false, message: "No test result submitted by the user." };
  }

  if (!response.ok) {
    throw new Error('Failed to fetch test result');
  }

  return response.json(); 
};
