import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap"; 
import { useNavigate } from 'react-router-dom';

import { Link } from "react-router-dom";
import * as Yup from "yup";
import config from "../../api/config.js";
import { useFormik } from "formik";
import logo from "../../assets/images/logo.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";

const ForgetPasswordPage = ({ history }) => {
  document.title = "Forget Password | Rectify You";

  const [forgetError, setForgetError] = useState(null);
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate(); 
  const validation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Invalid email format"),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const emailResponse = await fetch(
          `${config.apiBaseUrl}/check-email-existence`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: values.email }),
          }
        );

        if (!emailResponse.ok) {
          throw new Error("Email does not exist.");
        }

        const { status, message } = await emailResponse.json();
        if (status) {
          const resetResponse = await fetch(
            `${config.apiBaseUrl}/reset-password`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email: values.email }),
            }
          );

          if (!resetResponse.ok) {
            const errorData = await resetResponse.json(); 
            throw new Error(errorData.message || "Failed to send reset email."); 
          }

          const resetMessage = await resetResponse.json();
          setForgetSuccessMsg(
            resetMessage.message ||
              "Please check your email for further instructions."
          );
          setForgetError(null);
          setTimeout(() => {
            navigate('/login'); 
          }, 3000); 
        } else {
          setForgetError("Email does not exist.");
          setForgetSuccessMsg(null);
        }
      } catch (error) {
        setForgetError(error.message); 
        setForgetSuccessMsg(null);
      } finally {
        setLoading(false); 
      }
    },
  });

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <div className="text-center d-block auth-logo">
                                                <img
                                                    src={logo}
                                                    alt="Logo"
                                                    height="80"
                                                />
                                            </div>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                        <p className="text-muted mt-2">
                          Reset Password with Rectify You.
                        </p>
                      </div>

                      {forgetError && (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {forgetError}
                        </Alert>
                      )}
                      {forgetSuccessMsg && (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {forgetSuccessMsg}
                        </Alert>
                      )}

                      <Form
                        className="custom-form mt-4"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              Boolean(validation.errors.email)
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback>
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>

                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              disabled={loading} 
                            >
                              {loading ? (
                                <Spinner size="sm" color="light" /> 
                              ) : (
                                "Reset"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Remember Password?{" "}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Rectify. Developed By{" "}
                        <a
                          href="https://mobiliya.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-underline"
                        >
                          MOBILIYA TECHNOLOGIES LLP
                        </a>
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default ForgetPasswordPage;
