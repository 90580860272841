import React from "react";
import { Link } from "react-router-dom";

const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const CategoryId = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const CategoryName = (cell) => {
  return cell.value ? cell.value : '';
};



export {
  Id,
  CategoryId,
  CategoryName,
  
};