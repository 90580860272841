import React, { useEffect, useState, Fragment } from 'react';
import { Card, Container, CardBody, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './TableContainer'; // Import the pagination table component

const AppointmentsCancelled = () => {
  document.title = 'Canceled Appointment History | Rectify You - Counselor';

  const [appointments] = useState([
    {
      id: 1,
      date: '2024-09-25T10:00:00Z', // ISO date string
      clientName: 'John Doe',
      status: 'Canceled',
    },
    {
      id: 2,
      date: '2024-09-26T11:00:00Z',
      clientName: 'Jane Smith',
      status: 'Completed',
    },
    {
      id: 3,
      date: '2024-09-27T14:00:00Z',
      clientName: 'Alice Johnson',
      status: 'Canceled',
    },
    {
      id: 4,
      date: '2024-09-28T15:30:00Z',
      clientName: 'Bob Brown',
      status: 'Canceled',
    },
  ]);

  const [canceledAppointments, setCanceledAppointments] = useState([]);

  useEffect(() => {
    const filteredAppointments = appointments.filter((appointment) => appointment.status === 'Canceled');
    setCanceledAppointments(filteredAppointments);
  }, [appointments]);

  // Define the columns for the TableContainer component
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date', // Unique accessor for date
        Cell: ({ value }) => new Date(value).toLocaleDateString(), // Format date
      },
      {
        Header: 'Time',
        accessor: 'time', // Unique accessor for time
        Cell: ({ row }) => {
          const date = new Date(row.original.date);
          return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time
        },
      },
      {
        Header: 'Client Name',
        accessor: 'clientName',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Actions',
        accessor: 'id', // Use id for actions
        Cell: ({ value }) => (
          <button className="btn btn-primary w-10" onClick={() => handleViewDetails(value)}>
            View Details
          </button>
        ),
      },
    ],
    []
  );

  const handleViewDetails = (id) => {
    // Implement the logic to view appointment details
    // console.log(`View details for appointment ID: ${id}`);
  };

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Page" breadcrumbItem="Counselor" breadcrumbPage="Canceled Appointment History" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {canceledAppointments.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={canceledAppointments}
                      isGlobalFilter={true} // Enables the global search filter
                      isAddOptions={false}
                      customPageSize={10} // Set the default page size
                      className="table align-middle"
                      isBordered={true}
                      theadClass="thead-light"
                    />
                  ) : (
                    <p className="text-center">No canceled appointments found.</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default AppointmentsCancelled;
