let config;
let environment = "production"; // local,uat,spt,production
let api_version = "v1";

switch (environment) {
  case "local":
    config = {
      apiBaseUrl: `http://localhost:9999/${api_version}`, // update this to the correct local URL
    };
    break;
  case "uat":
    config = {
      apiBaseUrl: `https://api.rectifyyou.com:9999/${api_version}`, // update this to the correct UAT URL
    };
    break;
  case "spt":
    config = {
      apiBaseUrl: `https://api.rectifyyou.com:9999/${api_version}`, // update this to the correct SPT URL
    };
    break;
  case "production":
    config = {
      apiBaseUrl: `https://api.rectifyyou.com:9999/${api_version}`, // update this to the correct production URL
    };
    break;
  default:
    config = {
      apiBaseUrl: `https://api.rectifyyou.com:9999/${api_version}`, // update this to the correct production URL
    };
}

export default config;
