import React from "react";
import { Link } from "react-router-dom";

const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const HashId = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const UserName = (cell) => {
  return cell.value ? cell.value : '';
};

const UserEmail = (cell) => {
  return cell.value ? cell.value : '';
};

const UserMobile = (cell) => {
  return cell.value ? cell.value : '';
};


export {
  Id,
  HashId,
  UserName,
  
  UserEmail,
  UserMobile,
 
  // Qualifications  
};
