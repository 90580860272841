
import config from "../config";

export const TimeslotInsert = async (values) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const counselor_id = JSON.parse(storedAuthUser).hashid; // Unique identifier for the counselor

  const timeSlotsToInsert = [];

  // Prepare the data for each day and time slot
  const monthKey = `${values.selectedMonth.year}-${values.selectedMonth.month}`; // YYYY-MM format
  const daysInMonth = new Date(values.selectedMonth.year, values.selectedMonth.month + 1, 0).getDate();

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(values.selectedMonth.year, values.selectedMonth.month, day);
    const dayOfWeek = values.daysOfWeek[date.getDay()];

    if (values.selectedDays.includes(dayOfWeek)) {
      // Manually format the date as YYYY-MM-DD (local time)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for the month
      const dayOfMonth = String(date.getDate()).padStart(2, '0'); // Ensure two digits for the day
      const dateString = `${year}-${month}-${dayOfMonth}`; // Format date as YYYY-MM-DD

      values.selectedTimeSlots.forEach((timeSlot) => {
        timeSlotsToInsert.push({
          counselor_id: counselor_id,
          slot_date: dateString,
          slot_time: timeSlot,
          status: "available",
        });
      });
    }
  }

  // console.log({ timeSlotsToInsert });
  // Perform the API request
  const response = await fetch(`${config.apiBaseUrl}/time-slot/timeslot-insert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add the token in headers for authentication
    },
    body: JSON.stringify({ timeSlots: timeSlotsToInsert }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to insert time slots.");
  }

  return response.json(); // Return response data
};

  


  export const fetchTimeSlots = async (selectedMonth) => {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const  counselor_id = JSON.parse(storedAuthUser).hashid;
  
    const month = String(selectedMonth.month + 1).padStart(2, "0"); // Ensure month is in MM format
    const year = selectedMonth.year; // YYYY
  
    // Perform the API request
    const response = await fetch(
      `${config.apiBaseUrl}/time-slot/timeslot-fetch?counselor_id=${counselor_id}&month=${month}&year=${year}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Add token for authentication
        },
      }
    );
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch time slots.");
    }
  
    return response.json(); // Return the fetched time slots
  };




  export const TimeslotDelete = async (hashid) => {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
  
    const response = await fetch(`${config.apiBaseUrl}/time-slot/timeslot-delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ hashid }),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete time slot.");
    }
  
    return response.json(); // Return response data
  };