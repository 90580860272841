import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changelayoutMode } from "../../store/actions";  //actions

const Layout = (props) => {
  let navigate = useNavigate();
  useEffect(() => { 
    navigate("/login"); 
  }, []);
  return (
    <div></div>
  );
};

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default connect(mapStateToProps, {
  changelayoutMode,
})(Layout);
