import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  CardFooter,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  fetchAllTestCategoriesOnly,
  fetchTestsByCategory,
  fetchQuestionsByTest,
  updateQuestionWithOptions,
  addQuestionWithOptions,
  deleteQuestion,
} from "../../../api/admin/Tests";

const EditTest = () => {
  document.title = "Edit Test | Admin Panel";

  const [testCategories, setTestCategories] = useState([]);
  const [availableTests, setAvailableTests] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTestHashId, setSelectedTestHashId] = useState("");
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState([""]);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false); // Toggle state for modal

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState({
    questionIndex: null,
    optionIndex: null,
  });

  const confirmRemoveOptionField = (questionIndex, optionIndex) => {
    setOptionToDelete({ questionIndex, optionIndex });
    setShowConfirmDeleteModal(true);
  };

  // Toast Notification
  const notifySuccess = (message) =>
    toast.success(message || "Test updated successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const bottomrightnotify = (message) =>
    toast(message || "Test updated successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  // toast.error(message || "Failed to register counselor", {
  //   position: "bottom-right",
  //   hideProgressBar: true,
  //   className: "bg-danger text-white",
  //   icon: false,
  // });
  const notifyError = (message) =>
    toast.error(message || "Failed to update question", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
      icon: false,
    });

  // Fetch test categories on component mount
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchAllTestCategoriesOnly();
      if (response.status) {
        setTestCategories(response.data);
      } else {
        notifyError("Failed to fetch categories");
      }
    };
    fetchData();
  }, []);

 // Handle Category Change
const handleCategoryChange = async (e) => {
  const selected = e.target.value;
  setSelectedCategory(selected);

  setQuestions([]);
  validation.setFieldValue("questions", []); 

  if (selected) {
    const response = await fetchTestsByCategory(selected);
    if (response.status) {
      setAvailableTests(response.data);
    } else {
      notifyError("Failed to fetch tests");
    }
  } else {
    setAvailableTests([]);
  }
};

  const handleTestNameChange = async (e) => {
    const selectedTestHashId = e.target.value;
    setSelectedTestHashId(selectedTestHashId);

    if (selectedTestHashId) {
      const response = await fetchQuestionsByTest(selectedTestHashId);
      if (response.status && response.data.length > 0) {
        // Update questions if available
        setQuestions(response.data);
        validation.setFieldValue(
          "questions",
          response.data.map((q) => ({
            id: q.hashid,
            text: q.question_text,
            options: q.options || [],
          }))
        );
      } else {
        // Clear questions and notify user
        setQuestions([]);
        validation.setFieldValue("questions", []); // Clear Formik's questions
        notifyError(
          "No questions found for the selected test. Please add questions before proceeding."
        );
      }
    } else {
      // Clear state if no test is selected
      setQuestions([]);
      setSelectedTestHashId("");
      validation.setFieldValue("questions", []);
    }
  };

  // Function to delete a question
  const handleDeleteQuestion = async (questionIndex) => {
    const questionId = validation.values.questions[questionIndex].id; // Get the id of the question to delete
    const response = await deleteQuestion(questionId); // Call the delete API

    if (response.status) {
      bottomrightnotify("Question deleted successfully!");
      removeQuestion(questionIndex); // Remove the question from the state after successful deletion
    } else {
      notifyError("Failed to delete the question.");
    }
  };

  // Form Validation with Formik & Yup
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      questions: questions.map((question) => ({
        id: question.hashid,
        text: question.question_text,
        options: question.options || [],
      })),
    },
    validationSchema: Yup.object().shape({
      questions: Yup.array().of(
        Yup.object().shape({
          text: Yup.string().required("Please enter the question"),
          options: Yup.array()
            .of(Yup.string().required("Option is required"))
            .min(1, "At least one option is required"),
        })
      ),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      let updateResponses = [];
      for (const question of values.questions) {
        const response = await updateQuestionWithOptions(
          question.id,
          question.text,
          question.options
        );
        updateResponses.push(response);
      }

      if (updateResponses.every((resp) => resp.status)) {
        bottomrightnotify(); // Show success notification
      } else {
        notifyError("Some questions failed to update.");
      }
    },
  });

  // Handle changes in options
  const handleOptionChange = (questionIndex, optionIndex, e) => {
    const updatedQuestions = [...validation.values.questions];
    updatedQuestions[questionIndex].options[optionIndex] = e.target.value;
    validation.setFieldValue("questions", updatedQuestions);
  };

  // Function to add a new option field
  const addOptionField = (questionIndex) => {
    const updatedQuestions = [...validation.values.questions];
    updatedQuestions[questionIndex].options.push(""); // Add an empty option to the options array
    validation.setFieldValue("questions", updatedQuestions); // Update formik values
  };

  // Handle changes in questions
  const handleQuestionChange = (questionIndex, e) => {
    const updatedQuestions = validation.values.questions.map(
      (question, index) =>
        index === questionIndex
          ? { ...question, text: e.target.value }
          : question
    );
    validation.setFieldValue("questions", updatedQuestions);
  };

  // Remove an option field
  const removeOptionField = (questionIndex, optionIndex) => {
    const updatedQuestions = [...validation.values.questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    validation.setFieldValue("questions", updatedQuestions);
    toast.success("Option Removed successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white", // Set the background color to green
      icon: false, // Disable the icon
    });
  };

  // Function to remove a question
  const removeQuestion = (questionIndex) => {
    const updatedQuestions = [...validation.values.questions];
    updatedQuestions.splice(questionIndex, 1); // Remove the question at the specific index
    validation.setFieldValue("questions", updatedQuestions); // Update formik values
  };

  // Handle new question and options state
  const handleNewQuestionChange = (e) => {
    setNewQuestion(e.target.value);
  };

  const handleNewOptionChange = (index, e) => {
    const updatedOptions = [...newOptions];
    updatedOptions[index] = e.target.value;
    setNewOptions(updatedOptions);
  };

  const addNewOptionField = () => {
    setNewOptions([...newOptions, ""]);
  };

  const removeNewOptionField = (index) => {
    const updatedOptions = [...newOptions];
    updatedOptions.splice(index, 1);
    setNewOptions(updatedOptions);
  };

  const addNewQuestion = async () => {
    if (newQuestion && newOptions.length > 0) {
      const response = await addQuestionWithOptions(
        selectedTestHashId,
        newQuestion,
        newOptions
      );
      if (response.status) {
        bottomrightnotify("Question added successfully!");

        setNewQuestion("");
        setNewOptions([""]);
        setShowAddQuestionModal(false);
        const refreshedQuestions =
          await fetchQuestionsByTest(selectedTestHashId);
        setQuestions(refreshedQuestions.data);
        validation.setFieldValue(
          "questions",
          refreshedQuestions.data.map((q) => ({
            id: q.hashid,
            text: q.question_text,
            options: q.options || [],
          }))
        );
      } else {
        notifyError("Failed to add new question.");
      }
    } else {
      notifyError("Please provide a question and at least one option.");
    }
  };

  // Toggle the visibility of the "Add New Question" modal
  const toggleAddQuestionModal = () => {
    setShowAddQuestionModal(!showAddQuestionModal);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Edit Test"
            breadcrumbPage="Edit Test"
          />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="">
              <Col md={4}>
                <Card className="shadow-sm">
                  <CardBody>
                    <Label className="form-label">Test Category</Label>
                    <Input
                      type="select"
                      onChange={handleCategoryChange}
                      value={selectedCategory}
                      className="form-select"
                    >
                      <option value="">Select Category</option>
                      {testCategories.map((category) => (
                        <option key={category.hashid} value={category.hashid}>
                          {category.category_name}
                        </option>
                      ))}
                    </Input>
                  </CardBody>
                </Card>
              </Col>

              {availableTests.length > 0 && (
                <Col md={4}>
                  <Card className="shadow-sm">
                    <CardBody>
                      <Label className="form-label">Test Name</Label>
                      <Input
                        type="select"
                        onChange={handleTestNameChange}
                        className="form-select"
                      >
                        <option value="">Select Test Name</option>
                        {availableTests.map((test) => (
                          <option key={test.hashid} value={test.hashid}>
                            {test.test_name}
                          </option>
                        ))}
                      </Input>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            {validation.values.questions.length > 0 && (
              <Row className="mt-4">
                <Col md={8}>
                  <Card className="shadow-sm">
                    <CardBody>
                      <Label className="form-label fs-4">
                        Test Name:{" "}
                        {selectedTestHashId
                          ? availableTests.find(
                              (test) => test.hashid === selectedTestHashId
                            )?.test_name || "N/A"
                          : "N/A"}
                      </Label>

                      {validation.values.questions.map((question, index) => (
                        <div
                          key={question.id}
                          className="mb border dark p-3 mb-3 "
                        >
                          <Input
                            type="text"
                            placeholder={`Question ${index + 1}`}
                            value={question.text}
                            onChange={(e) => handleQuestionChange(index, e)}
                            className="mb-2 font-weight-bold  bg-light text-dark border-dark rounded-0"
                          />
                          {validation.touched.questions?.[index]?.text &&
                          validation.errors.questions?.[index]?.text ? (
                            <div className="text-danger">
                              {validation.errors.questions[index].text}
                            </div>
                          ) : null}
                          {question.options.map((option, optionIndex) => (
                            <div
                              className="d-flex align-items-center mb-2 "
                              key={optionIndex}
                            >
                              <Input
                                type="text"
                                placeholder={`Option ${optionIndex + 1}`}
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(index, optionIndex, e)
                                }
                                className="me-2"
                                style={{ width: "40%" }}
                              />
                              {question.options.length > 1 && (
                                <Button
                                  color="warning"
                                  size="sm"
                                  onClick={() =>
                                    confirmRemoveOptionField(index, optionIndex)
                                  } // Call confirmation function
                                >
                                  &minus;
                                </Button>
                              )}
                            </div>
                          ))}
                          <Button
                            color="primary"
                            size="sm"
                            className="mb-2"
                            onClick={() => addOptionField(index)}
                          >
                            Add Option
                          </Button>
                          {/* Add delete button here */}
                          <Button
                            color="danger"
                            size="sm"
                            className="mb-2 ms-2"
                            onClick={() => handleDeleteQuestion(index)} // Call delete handler
                          >
                            Delete Question
                          </Button>
                        </div>
                      ))}
                    </CardBody>
                    <CardFooter className="d-flex justify-content-between">
                      <div>
                        <Button
                          color="primary"
                          onClick={toggleAddQuestionModal}
                        >
                          Add new questions
                        </Button>
                      </div>
                      <div>
                        <Button type="submit" color="success">
                          Save Changes
                        </Button>
                      </div>
                    </CardFooter>
                    <CardFooter>
                      <div className="d-flex gap-2">
                        <Link to="/tests" className="ms-auto">
                          <Button type="button" color="warning">
                            Back
                          </Button>
                        </Link>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            )}
          </Form>
        </Container>
      </div>

      {/* Add New Question Modal */}
      <Modal isOpen={showAddQuestionModal} toggle={toggleAddQuestionModal}>
        <ModalHeader toggle={toggleAddQuestionModal}>
          Add New Question
        </ModalHeader>
        <ModalBody>
          <Label className="form-label">New Question</Label>
          <Input
            type="text"
            value={newQuestion}
            onChange={handleNewQuestionChange}
            placeholder="Enter your question"
          />
          <Label className="form-label mt-3">Options</Label>
          {newOptions.map((option, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Input
                type="text"
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleNewOptionChange(index, e)}
                className="me-2"
              />
              {newOptions.length > 1 && (
                <Button
                  color="warning"
                  size="sm"
                  onClick={() => removeNewOptionField(index)}
                >
                  &minus;
                </Button>
              )}
            </div>
          ))}
          <Button
            color="primary"
            size="sm"
            onClick={addNewOptionField}
            className="mt-2"
          >
            Add Option
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addNewQuestion}>
            Submit
          </Button>{" "}
          <Button color="secondary" onClick={toggleAddQuestionModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showConfirmDeleteModal}
        toggle={() => setShowConfirmDeleteModal(false)}
      >
        <ModalHeader toggle={() => setShowConfirmDeleteModal(false)}>
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this option? This action cannot be
          undone.
          <br />
          <strong>Note:</strong> You need to save your changes for the option to
          be removed.
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              removeOptionField(
                optionToDelete.questionIndex,
                optionToDelete.optionIndex
              );
              setShowConfirmDeleteModal(false);
            }}
          >
            Remove
          </Button>
          <Button
            color="secondary"
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default EditTest;
