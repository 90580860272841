import React from "react";
import { Link } from "react-router-dom";

const Id = ({ cell }) => {
  return <span>{cell.value || ''}</span>;
};

const CategoriesId = ({ cell }) => {
  return (
    <Link className="text-dark fw-medium" to="#">
      {cell.value || 'N/A'}
    </Link>
  );
};

const CategoriesName = ({ cell }) => {
  return <span>{cell.value || 'No Category'}</span>;
};

const OrderPriority = ({ cell }) => {
  return <span>{cell.value || 'Not Set'}</span>;
};

export {
  Id,
  CategoriesId,
  CategoriesName,
  OrderPriority,
};
