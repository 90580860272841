import React from "react";
import { Link } from "react-router-dom";

const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const HashId = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const OwnerName = (cell) => {
  return cell.value ? cell.value : '';
};

const CounselorName = (cell) => {
  return cell.value ? cell.value : '';
};

const EmailAddress = (cell) => {
  return cell.value ? cell.value : '';
};

const MobileNo = (cell) => {
  return cell.value ? cell.value : '';
};

const StartDate = (cell) => {
  return cell.value ? cell.value : '';
};

const EndDate = (cell) => {
  return cell.value ? cell.value : '';
};

const Category = (cell) => {
  return cell.value ? cell.value : '';
};

export {
  Id,
  HashId,
  OwnerName,
  CounselorName,  
  EmailAddress,
  MobileNo,
  StartDate,
  EndDate,
  Category
  // Qualifications  
};
