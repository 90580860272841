import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const EditVendorProfile = () => {
  document.title = "Tests | Rectifyyou - Tests";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Tests"
            breadcrumbPage="Tests"
          />
          {/* <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <ul className="nav d-flex">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/company/company-profile">
                                                <FeatherIcon icon="user" width={22} height={22} />
                                                <span className="m-2 font-size-17">Company Profile</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/company/company-setting">
                                                <FeatherIcon icon="settings" width={22} height={22} />
                                                <span className="m-2 font-size-17">Company Setting</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/company/change-password">
                                                <FeatherIcon icon="lock" width={22} height={22} />
                                                <span className="m-1 font-size-17">Change Password</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
          <Row className="mb-3">
            <Col md={12}>
              {/* <h5 className="font-size-18">Test Masters</h5> */}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav d-flex">
                    <li className="nav-item">
                      <Link className="nav-link" to="/tests/test-categories">
                        <FeatherIcon icon="list" width={22} height={22} />
                        <span className="m-2 font-size-17">
                          Add Test Categories
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/tests/tests-list">
                        <FeatherIcon icon="file-plus" width={22} height={22} />
                        <span className="m-2 font-size-17">Create Test</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/tests/add-questions">
                        <FeatherIcon
                          icon="help-circle"
                          width={22}
                          height={22}
                        />
                        <span className="m-1 font-size-17">Add Questions</span>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/tests/edit-test">
                        <FeatherIcon icon="file-text" width={22} height={22} />
                        <span className="m-1 font-size-17">Edit Test</span>
                      </Link>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditVendorProfile;
