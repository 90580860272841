// TimeSlotTable.js
import React from 'react';

const TimeSlotTable = ({ fetchedSlots, selectedMonth, formatDateWithDay, handleDeleteSlot }) => {
    const monthKey = `${selectedMonth.year}-${selectedMonth.month}`;

    if (!fetchedSlots[monthKey] || Object.keys(fetchedSlots[monthKey]).length === 0) {
        return <p>No slots added for this month yet.</p>;
    }

    return (
        <table className="table table-bordered mt-4">
            <thead>
                <tr>
                    <th style={{ width: "200px" }}>Day & Date</th>
                    <th>Slots</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(fetchedSlots[monthKey]).map((date) => (
                    <tr key={date}>
                        <td>{formatDateWithDay(date)}</td>
                        <td>
                            <div className="d-flex flex-wrap">
                                {fetchedSlots[monthKey][date].map((slot, index) => (
                                    <div key={index} className="m-1">
                                        <span className="badge bg-secondary p-2">
                                            {slot.slotTime}
                                            <button
                                                className="btn btn-danger btn-sm ms-2"
                                                onClick={() => handleDeleteSlot(date, slot.slotTime)}
                                            >
                                                X
                                            </button>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TimeSlotTable;