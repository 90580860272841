import React, { useState, useEffect, useMemo } from 'react';
import { Card, Container, CardBody, Row, Col } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './TableContainer';
import { fetchAllBookings } from '../../api/admin/Bookings';
import { useNavigate } from 'react-router-dom';

const AppointmentsList = () => {
  document.title = 'Booked Appointments | Rectify You - Counselor';

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedAppointments = await fetchAllBookings();

        if (fetchedAppointments.length === 0) {
          setError('No appointments found.');
        } else {
          // Sort appointments by booking_date in descending order
          const sortedAppointments = fetchedAppointments.sort(
            (b, a) => new Date(a.booking_date) - new Date(b.booking_date)
          );
          setAppointments(sortedAppointments);
        }
      } catch (error) {
        setError(error.message || 'Failed to fetch appointments.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStatusChange = (id, newStatus) => {
    setAppointments((prevAppointments) =>
      prevAppointments.map((appointment) =>
        appointment.booking_hashid === id
          ? { ...appointment, slot_status: newStatus }
          : appointment
      )
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: (row) => `${row.user_first_name} ${row.user_last_name}`,
      },
      {
        Header: 'Date',
        accessor: 'booking_date',
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
      },
      {
        Header: 'Time',
        accessor: 'slot_time',
      },
      {
        Header: 'Counseling Type',
        accessor: 'problem_category',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary w-10"
            onClick={() => handleViewDetails(row.original.booking_hashid)}
          >
            View Details
          </button>
        ),
      },
    ],
    []
  );

  const handleViewDetails = (hashid) => {
    navigate(`/appointments-list/booking-details/${hashid}`);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Page"
          breadcrumbItem="Appointments List"
          breadcrumbPage="Booked Appointments"
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <h5>Loading appointments...</h5>
                  </div>
                ) : error ? (
                  <div className="text-center">
                    <h5>{error}</h5>
                  </div>
                ) : appointments.length > 0 ? (
                  <TableContainer
                    columns={columns}
                    data={appointments}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle"
                    isBordered={true}
                    theadClass="thead-dark"
                  />
                ) : (
                  <div className="text-center">
                    <h5>No appointments found.</h5>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppointmentsList;
