import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  CardFooter,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { fetchAllCategories, updateCategoriesPriority } from "../../../api/admin/Counselor";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const SetMenuPriority = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchAllCategories();
        if (response.status) {
          setCategories(response.data);
        } else {
          toast.error("Failed to fetch categories");
        }
      } catch (error) {
        toast.error("Error fetching categories");
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (values) => {
    const formattedData = Object.entries(values).map(([hashid, order_priority]) => ({
      id: hashid,
      order_priority: parseInt(order_priority, 10),
    }));

    try {
      const response = await updateCategoriesPriority({ categories: formattedData });
      if (response.status) {
        toast.success("Menu Priority Set successfully!", {
          position: "bottom-right",
          hideProgressBar: true,
          className: "bg-success text-white",
          icon:false,
          autoClose: 5000,
        });
      } else {
        toast.error("Failed to update priority: " + response.message);
      }
    } catch (error) {
      toast.error("Error updating priority");
    }
  };

  // Check if categories are loaded correctly
  useEffect(() => {
    if (categories.length > 0) {
      // console.log("Fetched categories:", categories);
    }
  }, [categories]);

  // Formik Validation Schema
  const validationSchema = Yup.object({
    ...categories.reduce((acc, category) => {
      acc[category.hashid] = Yup.number()
        .required("Priority is required")
        .min(1, "Priority must be at least 1")
        .max(100, "Priority must be less than 100")
        .test('unique', 'Priority must be unique', function(value) {
          // Access all the priorities to check for duplicates
          const otherValues = Object.values(this.parent);
          const count = otherValues.filter((v) => v === value).length;
          return count === 1; // Ensure there is only one instance of each priority
        });
      return acc;
    }, {}),
  });

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbPage="Set Menu Priority"
            breadcrumbItem="Set Menu Bar Priority"
          />
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={categories.reduce((acc, category) => {
                      acc[category.hashid] = category.order_priority || ""; // Ensure order_priority is set
                      return acc;
                    }, {})}
                    enableReinitialize={true}  // Allow Formik to reinitialize when categories change
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema} // Apply the validation schema
                  >
                    {({ values, handleChange, touched }) => (
                      <Form>
                        <div className="d-flex flex-column">
                          {categories.map((category) => (
                            <div
                              key={category.hashid}
                              className="d-flex justify-content-between align-items-center mb-3 p-2 border rounded"
                            >
                              <span className="flex-grow-1">{category.category_name}</span>
                              <Field
                                name={category.hashid}
                                render={({ field }) => (
                                  <div>
                                    <Input
                                      {...field}
                                      type="number"
                                      value={values[category.hashid] || ""}
                                      onChange={handleChange}
                                      placeholder="Priority"
                                      min="1"
                                      className="ms-3"
                                      style={{ width: "100px" }}
                                    />
                                    <ErrorMessage name={category.hashid} component="div" className="text-danger" />
                                  </div>
                                )}
                              />
                            </div>
                          ))}
                        </div>
                        <CardFooter>
                          <div className="d-flex justify-content-between mt-3">
                            <Link to="/settings/categories-list">
                              <Button type="button" color="warning">
                                Back
                              </Button>
                            </Link>
                            <Button type="submit" color="primary">
                              Set Priority
                            </Button>
                          </div>
                        </CardFooter>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SetMenuPriority;
