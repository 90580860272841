import React, { useEffect, useState } from "react";
import { Container, Alert } from "reactstrap";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getCategoryById,
  CategoriesUpdate,
} from "../../../api/admin/Counselor";

import "react-toastify/dist/ReactToastify.css";

const CategoriesEdit = () => {
  // Page title
  document.title = "Edit Categories | Rectify You - Company";

  const [error, setError] = useState(null);
  const { hashid } = useParams(); // Capture hashid from URL params
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState({
    category_name: "",
    status: "",
   
  });

  // Fetch category details when the component loads
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await getCategoryById(hashid); // Fetch category details by ID
        const data = await response.json();
        if (response.status === 200) {
          setCategoryData({
            category_name: data.category_name,
            status: data.status,
           
          });
        } else {
          console.error("Failed to fetch category", data.message);
          setError(data.message);
        }
      } catch (error) {
        console.error("Error occurred", error.message);
        setError(error.message);
      }
    };
    fetchCategory();
  }, [hashid]);

  const bottomrightnotify = () =>
    toast("Category Updated Successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: categoryData, // Set initial values from fetched data
    validationSchema: Yup.object({
      category_name: Yup.string().required("Please Enter Category Name"),
      status: Yup.string()
        .oneOf(["active", "deactive"], "Invalid Status")
        .required("Please Enter Status"),
     
    }),
    onSubmit: async (values) => {
      try {
        const updateResponse = await CategoriesUpdate(hashid, values);
        const data = await updateResponse.json();
        if (updateResponse.status === 200) {
          bottomrightnotify();
          setTimeout(() => {
            navigate("/settings/Categories-list");
          }, 5000);
        } else {
          console.error("Update failed", data.message);
          setError(updateResponse.message);
        }
      } catch (error) {
        console.error("An error occurred", error.message);
        setError(error.message);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-contents" style={{ marginTop: "10rem" }}>
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Categories"
            breadcrumbPage="Edit Category"
          />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={8}>
                        <Label className="form-Label">Category Name *</Label>
                        <Input
                          name="category_name"
                          id="category_name"
                          type="text"
                          className="form-control"
                          placeholder="Category Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category_name || ""}
                          invalid={
                            validation.touched.category_name &&
                            validation.errors.category_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.category_name &&
                        validation.errors.category_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.category_name}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      {/* <Col md={4}>
                        <Label className="form-Label">Order Priority</Label>
                        <Input
                          type="text"
                          name="priority"
                          id="priority"
                          className="form-control"
                          placeholder="Set Priority"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.priority || ""}
                          invalid={
                            validation.touched.priority &&
                            validation.errors.priority
                              ? true
                              : false
                          }
                        />
                        {validation.touched.priority &&
                        validation.errors.priority ? (
                          <FormFeedback type="invalid">
                            {validation.errors.priority}
                          </FormFeedback>
                        ) : null}
                      </Col> */}
                      <Col md={4}>
                        <Label className="form-Label">Status *</Label>
                        <Input
                          type="select"
                          name="status"
                          id="status"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status || ""}
                          invalid={
                            validation.touched.status &&
                            validation.errors.status
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="active">Active</option>
                          <option value="deactive">Deactive</option>
                        </Input>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        Update
                      </Button>
                      <Link to="/settings/Categories-list">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoriesEdit;
