import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Alert,
  CardHeader,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
  CardBody,
} from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { fetchCounselorProfileInfo, CounselorProfileUpdate } from "../../api/counselor/CounselorSetting";
import {
  fetchAllCategories,
  fetchCounselorInfo,
  CounselorProfileUpdate,
} from "../../api/admin/Counselor";

const CounselorProfile = () => {
  document.title = "Counselor Profile | Rectify You - Company";
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const location = useLocation();
  const [specialistList, setSpecialistList] = useState([]);
  const [specialistInput, setSpecialistInput] = useState("");

  const [languageInput, setLanguageInput] = useState("");
  const [languageList, setLanguageList] = useState([]);

  const [profile, setCounselorProfile] = useState([]);
  const [categories, setCategories] = useState([]);
  const [counselorprofile, CounselorProfile] = useState([]);
  const [headingWordCount, setheadingWordCount] = useState(0);
  const [descriptionWordCount, setDescriptionWordCount] = useState(0);

  const maxheadingWords = 15; // Limit for heading (short and concise)
  const maxDescriptionWords = 150; // Limit for description
  const handleWordCount = (e, setWordCount, maxWords, validationField) => {
    const words = e.target.value.trim().split(/\s+/);
    setWordCount(words.length);

    // Only update form values if word count is within the limit
    if (words.length <= maxWords) {
      validation.setFieldValue(validationField, e.target.value);
    }
  };

  useEffect(() => {
    const getCounselorInfo = async () => {
      try {
        const counselorData = await fetchCounselorInfo();
        setCounselorProfile(counselorData[0]);
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    };
    getCounselorInfo();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchAllCategories();
        if (response.status) {
          setCategories(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const bottomrightnotify = () =>
    toast("Counselor Profile Update Successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: profile.first_name || "",
      middle_name: profile.middle_name || "",

      last_name: profile.last_name || "",
      mobile: profile.mobile || "",
      email: profile.email || "",
      status: profile.status || "",
      password: profile.password || "",
      // role: profile.role || "",
      gender: profile.gender || "",

      profile_pic: profile.profile_pic || "",
      specialist: profile.specialist
        ? profile.specialist.split(",").map((item) => item.trim())
        : [],
      languages: profile.languageList
        ? profile.languageList.split(",").map((item) => item.trim())
        : [],
      marital_status: profile.marital_status || "",
      experience: profile.experience || null,
      age: profile.age || null,
      description: profile.description || "",
      heading: profile.heading || "",
      price: profile.price || null,
      originalPrice: profile.original_price || null,
      occupation: profile.occupation || "",
      education: profile.education || "",
      categories: profile.categories
        ? profile.categories.split(",").map((c) => c.trim())
        : [],
      start_date: profile.start_date || "",
      end_date: profile.end_date || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Mobile Number is not valid")
        .required("Please Enter Your Mobile Number"),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Please Enter Your Email"),

      password: Yup.string().required("Please Enter Your Password"),
      marital_status: Yup.string().required(
        "Please Select Your Marital Status"
      ),
      gender: Yup.string()
        .oneOf(["M", "F", "O"], "Invalid gender")
        .required("Please Enter Your Gender"),
      heading: Yup.string().required("Please enter a heading"),
      description: Yup.string().required("Please enter a description"),
      // categories: Yup.array()
      //   .min(1, "Please select at least one category")
      //   .required("Please select at least one category"),
      // languages: Yup.array()
      //   .min(1, "Please add at least one language")
      //   .required("Please add at least one language"),
      // specialist: Yup.array()
      //   .min(1, "Please add at least one specialty")
      //   .required("Please add at least one specialty"),
    }),
    onSubmit: async (values) => {
      values.specialist = specialistList.join(", ");
      values.languages = languageList.join(", ");

      try {
        setLoading(true);

        const profileData = await CounselorProfileUpdate(values);
        const data = await profileData.json();

        if (profileData.status === 200) {
          bottomrightnotify();
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          console.error("Updation failed", data.message);
          toast.error(
            "Updation failed: " +
              "This email or mobile number is already registered with another user.",
            {
              position: "bottom-right",
              hideProgressBar: true,
              className: "bg-danger text-white",
            }
          );
          setError(profileData.message);
        }

        CounselorProfile(profileData);
      } catch (error) {
        console.error("Error occurred while updating profile:", error);

        setError("An error occurred while updating the profile.");
      } finally {
        setLoading(false);
      }
    },
  });

  //for base64 image rendering
  const isPdf = (base64String) => {
    return base64String.startsWith("data:application/pdf;base64,");
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    const maxSize = 3 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        alert("File size exceeds 3 MB. Please upload a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        validation.setFieldValue(field, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (profile.specialist) {
      // Set the specialist list based on the fetched profile data
      setSpecialistList(
        profile.specialist.split(",").map((item) => item.trim())
      );
    }

    if (profile.languages) {
      // Set the languages list based on the fetched profile data
      setLanguageList(profile.languages.split(",").map((item) => item.trim()));
    }
  }, [profile.specialist, profile.languages]);

  const addSpecialist = () => {
    if (specialistInput.trim() !== "") {
      setSpecialistList([...specialistList, specialistInput.trim()]);
      setSpecialistInput(""); // Clear input
    }
  };

  // Handle removing a specialty
  const removeSpecialist = (index) => {
    const updatedList = specialistList.filter((_, i) => i !== index);
    setSpecialistList(updatedList);
  };

  const addLanguage = () => {
    if (languageInput.trim() !== "") {
      setLanguageList([...languageList, languageInput.trim()]);
      setLanguageInput(""); // Clear input after adding
    }
  };

  const removeLanguage = (index) => {
    const newList = [...languageList];
    newList.splice(index, 1);
    setLanguageList(newList);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Counselor Profile"
            breadcrumbPage="Counselor Profile"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">First Name *</Label>
                        <Input
                          name="first_name"
                          placeholder="First Name"
                          type="text"
                          className="form-control"
                          id="first_name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                          validation.errors.first_name && (
                            <FormFeedback type="invalid">
                              {validation.errors.first_name}
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Middle Name</Label>
                        <Input
                          name="middle_name"
                          placeholder="Middle Name"
                          className="form-control"
                          type="text"
                          id="middle_name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.middle_name || ""}
                        />
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Last Name *</Label>
                        <Input
                          name="last_name"
                          placeholder="Last Name"
                          type="text"
                          className="form-control"
                          id="last_name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                          validation.errors.last_name && (
                            <FormFeedback type="invalid">
                              {validation.errors.last_name}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Mobile Number *</Label>
                        <Input
                          name="mobile"
                          placeholder="Mobile Number"
                          type="text"
                          className="form-control"
                          id="mobile"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ""}
                          invalid={
                            validation.touched.mobile &&
                            validation.errors.mobile
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mobile &&
                          validation.errors.mobile && (
                            <FormFeedback type="invalid">
                              {validation.errors.mobile}
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">E-Mail *</Label>
                        <Input
                          name="email"
                          id="email"
                          placeholder="Email"
                          type="email"
                          readOnly
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      {/* <Col md={4}>
                        <Label className="form-label">  Password * </Label>
                        <Input
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </Col>      */}
                    </Row>

                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Gender *</Label>
                        <Input
                          type="select"
                          name="gender"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gender}
                          invalid={
                            validation.touched.gender &&
                            validation.errors.gender
                              ? true
                              : false
                          }
                        >
                          <option value="">Select gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Other</option>
                        </Input>
                        {validation.touched.gender &&
                        validation.errors.gender ? (
                          <FormFeedback type="invalid">
                            {validation.errors.gender}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Age *</Label>
                        <Input
                          name="age"
                          placeholder="Age"
                          type="number"
                          className="form-control"
                          id="age"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.age || ""}
                        />
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Marital Status *</Label>
                        <Input
                          type="select"
                          name="marital_status"
                          id="marital_status"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.marital_status}
                          invalid={
                            validation.touched.marital_status &&
                            validation.errors.marital_status
                              ? true
                              : false
                          }
                        >
                          <option>Select Marital</option>
                          <option>Single</option>
                          <option>Married</option>
                          <option>Divorced</option>
                        </Input>
                        {validation.touched.marital_status &&
                        validation.errors.marital_status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.marital_status}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Occupation *</Label>
                        <Input
                          name="occupation"
                          placeholder="Occupation"
                          type="text"
                          className="form-control"
                          id="occupation"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.occupation || ""}
                        />
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Education *</Label>
                        <Input
                          name="education"
                          placeholder="Education"
                          type="text"
                          className="form-control"
                          id="education"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.education || ""}
                        />
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Experience*</Label>
                        <Input
                          name="experience"
                          id="experience"
                          label="experience"
                          placeholder="Enter Only experience"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.experience || ""}
                          invalid={
                            validation.touched.experience &&
                            validation.errors.experience
                              ? true
                              : false
                          }
                        />
                        {validation.touched.experience &&
                        validation.errors.experience ? (
                          <FormFeedback type="invalid">
                            {validation.errors.experience}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Original Fees *</Label>
                        <Input
                          name="originalPrice"
                          placeholder="originalPrice"
                          type="number"
                          className="form-control"
                          id="originalPrice"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.originalPrice || ""}
                        />
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Discount Fees *</Label>
                        <Input
                          name="price"
                          placeholder="price"
                          type="number"
                          className="form-control"
                          id="price"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.price || ""}
                        />
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">
                          Profile_pic Upload *
                        </Label>
                        <div className="d-flex align-items-center">
                          <Input
                            name="profile_pic_upload"
                            placeholder="profile_pic Upload"
                            type="file"
                            className="form-control"
                            id="profile_pic_upload"
                            accept="image/png, image/gif, image/jpeg ,image/webp"
                            onChange={(e) => handleFileChange(e, "profile_pic")}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.profile_pic &&
                              validation.errors.profile_pic
                                ? true
                                : false
                            }
                          />
                          {profile.profile_pic && (
                            <img
                              src={profile.profile_pic}
                              alt="profile_pic"
                              className="ms-3"
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const newWindow = window.open();
                                newWindow.document.write(
                                  `<html><body><img src="${profile.profile_pic}" style="display: flex; justify-content: center; align-items: center; height: 70vh; margin: 0 auto"/></body></html>`
                                );
                                newWindow.document.close();
                              }}
                            />
                          )}
                        </div>
                        {validation.touched.profile_pic &&
                        validation.errors.profile_pic ? (
                          <FormFeedback type="invalid">
                            {validation.errors.profile_pic}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Label className="form-label">Display Title *</Label>
                        <Input
                          type="textarea"
                          id="heading"
                          name="heading"
                          className="form-control"
                          value={validation.values.heading || ""}
                          onChange={(e) =>
                            handleWordCount(
                              e,
                              setheadingWordCount,
                              maxheadingWords,
                              "heading"
                            )
                          }
                          onBlur={validation.handleBlur}
                          rows="1"
                          invalid={
                            !!(
                              validation.touched.heading &&
                              validation.errors.heading
                            )
                          }
                        />
                        <FormFeedback>{validation.errors.heading}</FormFeedback>
                        <small className="text-muted">
                          {headingWordCount}/{maxheadingWords} words
                        </small>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Label className="form-label">Description *</Label>
                        <Input
                          type="textarea"
                          id="description"
                          name="description"
                          className="form-control"
                          value={validation.values.description || ""}
                          onChange={(e) =>
                            handleWordCount(
                              e,
                              setDescriptionWordCount,
                              maxDescriptionWords,
                              "description"
                            )
                          }
                          onBlur={validation.handleBlur}
                          rows="5"
                          invalid={
                            !!(
                              validation.touched.description &&
                              validation.errors.description
                            )
                          }
                        />
                        <FormFeedback>
                          {validation.errors.description}
                        </FormFeedback>
                        <small className="text-muted">
                          {descriptionWordCount}/{maxDescriptionWords} words
                        </small>
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row></Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Specialist In *</Label>
                        <div className="d-flex">
                          <Input
                            type="text"
                            placeholder="Add  specialty"
                            value={specialistInput}
                            onChange={(e) => setSpecialistInput(e.target.value)}
                          />
                          <Button
                            color="primary"
                            className="ms-2"
                            onClick={addSpecialist}
                          >
                            Add
                          </Button>
                        </div>

                        {/* Display the list of specialties */}
                        <div className="mt-2 d-flex flex-wrap">
                          {specialistList.map((specialty, index) => (
                            <div key={index} className="me-2 mb-2">
                              <span
                                className="badge bg-light  py-2 px-3"
                                style={{ fontSize: "0.6rem", color: "#333333" }}
                              >
                                {specialty}{" "}
                                <button
                                  type="button"
                                  className="btn-close btn-close-sm ms-1 p-0"
                                  onClick={() => removeSpecialist(index)}
                                  aria-label="Close"
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Languages *</Label>
                        <div className="d-flex">
                          <Input
                            type="text"
                            placeholder="Add a language"
                            value={languageInput}
                            onChange={(e) => setLanguageInput(e.target.value)}
                          />
                          <Button
                            color="primary"
                            className="ms-2"
                            onClick={addLanguage}
                          >
                            Add
                          </Button>
                        </div>

                        {/* Display the list of languages */}
                        <div className="mt-2 d-flex flex-wrap">
                          {languageList.map((language, index) => (
                            <div key={index} className="me-2 mb-2">
                              <span
                                className="badge bg-light  py-2 px-3"
                                style={{ fontSize: "0.6rem", color: "#333333" }}
                              >
                                {language}{" "}
                                <button
                                  type="button"
                                  className="btn-close btn-close-sm ms-1 p-0"
                                  onClick={() => removeLanguage(index)}
                                  aria-label="Close" // Accessibility improvement
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">Categories *</Label>
                        <Select
                          isMulti
                          name="categories"
                          options={categories.map((category) => ({
                            value: category.hashid,
                            label: category.category_name,
                          }))}
                          classNamePrefix="select"
                          value={validation.values.categories.map(
                            (category) => ({
                              value: category,
                              label:
                                categories.find(
                                  (cat) => cat.hashid === category
                                )?.category_name || category,
                            })
                          )}
                          onChange={(selectedOptions) => {
                            // Update categories with only the newly selected values
                            const newCategories = selectedOptions.map(
                              (option) => option.value
                            );
                            validation.setFieldValue(
                              "categories",
                              newCategories
                            );
                          }}
                          onBlur={() =>
                            validation.setFieldTouched("categories", true)
                          }
                        />

                        {validation.touched.categories &&
                          validation.errors.categories && (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "block" }}
                            >
                              {validation.errors.categories}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-xs btn"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                      <Link to="/counselor/settings">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CounselorProfile;
