import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  CardFooter,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "./DeleteModal";
import TableContainer from "./components/TableContainer";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { fetchAllTestList, deleteTest } from "../../../api/admin/Tests";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const Route = () => {
  document.title = "All Tests List | Rectify - Tests";
  const [route, setRoute] = useState([]);
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedTestHashid, setSelectedTestHashid] = useState(null);

  useEffect(() => {
    const getRoute = async () => {
      try {
        const routeData = await fetchAllTestList();

        if (routeData.status) {
          setRoute(routeData.data);
        } else {
          console.error("Failed to fetch tests:", routeData.message);
        }
      } catch (error) {
        console.error("Failed to fetch tests:", error);
      }
    };
    getRoute();
  }, []);

  const handleDeleteClick = (test_hashid) => {
    setSelectedTestHashid(test_hashid);
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteTest(selectedTestHashid);
      if (response.status) {
        setRoute((prev) =>
          prev.filter((test) => test.test_hashid !== selectedTestHashid)
        );
        toast.success("Test deleted successfully!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Failed to delete test: " + response.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error deleting test:", error);
      toast.error("Error deleting test", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
    setIsDeleteModalVisible(false);
    setSelectedTestHashid(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Test Name",
        accessor: "test_name",
        filterable: true,
        disableFilters: true,
      },
      {
        Header: "Category Name",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
      },
      {
        Header: "Action",
        Cell: (route) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                tag="a"
              >
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  tag={Link}
                  to={`/tests/edit-test-name/${route.row.original.test_hashid}`}
                >
                  Edit Test Name
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() =>
                    handleDeleteClick(route.row.original.test_hashid)
                  }
                >
                  Delete Test
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Create Tests"
            breadcrumbPage="All Tests List"
          />
          <Row>
            <Col lg="12">
              <Row>
                <div className="col-sm d-flex align-items-start justify-content-end mb-3">
                  <Link
                    to="/tests/create-test"
                    className="btn btn-primary btn-rounded btn-sm waves-effect waves-light"
                  >
                    <i className="bx me-1"></i> Create Test
                  </Link>
                </div>
              </Row>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={route}
                    isGlobalFilter={true}
                    customPageSize={10}
                  />
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                    <Link to="/tests">
                      <Button type="button" color="warning">
                        Back
                      </Button>
                    </Link>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
        <DeleteModal
          show={isDeleteModalVisible}
          onDeleteClick={confirmDelete}
          onCloseClick={() => setIsDeleteModalVisible(false)}
        />
      </div>
    </React.Fragment>
  );
};

export default Route;
