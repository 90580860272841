import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import { getDetailsCount } from "../../api/admin/Counselor";
import { Link } from "react-router-dom";

const Dashboard = () => {
  document.title = "Dashboard | Rectify you";

  const [activeData, setActiveData] = useState({
    total_users: 0,
    total_counselors: 0,
    total_enquiries: 0,
    total_revenue: 0,
    total_test_categories: 0,
    total_tests: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const detailsData = await getDetailsCount();
        setActiveData(detailsData.data);
      } catch (error) {
        console.error("Failed to fetch Details counts:", error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Dashboard"
            breadcrumbPage="Dashboard"
          />
          <Row>
            {/* Card for Total Users */}
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/users-list`} className="text-dark">
                  <CardBody>
                    <h5>Registered Users</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.total_users} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            {/* Card for Total Counselors */}
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/Counselors`} className="text-dark">
                  <CardBody>
                    <h5>Registered Counselors</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.total_counselors} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            {/* Card for Total Enquiries */}
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/admin/enquiry`} className="text-dark">
                  <CardBody>
                    <h5>Total Enquiries</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.total_enquiries} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            {/* Card for Total Revenue */}
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                {/* <Link to={`/revenue`} className="text-dark"> */}
                <CardBody>
                  <h5>Total Revenue</h5>
                  <div className="text-nowrap">
                    <span
                      className="ms-1 text-bold font-size-20"
                      style={{ color: "#5156be" }}
                    >
                      {activeData.total_revenue.toLocaleString()}
                    </span>
                  </div>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      Total Amount
                    </span>
                  </div>
                </CardBody>
                {/* </Link> */}
              </Card>
            </Col>
            {/* Card for Total Test Categories */}
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/tests/test-categories`} className="text-dark">
                  <CardBody>
                    <h5>Total Test Categories</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.total_test_categories} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            {/* Card for Total Tests */}
            <Col xl={3} md={6}>
              <Card className="card-h-100">
                <Link to={`/tests/tests-list`} className="text-dark">
                  <CardBody>
                    <h5>Total Tests</h5>
                    <div className="text-nowrap">
                      <span
                        className="ms-1 text-bold font-size-20"
                        style={{ color: "#5156be" }}
                      >
                        <CountUp end={activeData.total_tests} />
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <span className="ms-1 text-muted font-size-13">
                        Total Count
                      </span>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
