import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  fetchAllTestCategories,
  TestNameInsert,
} from "../../../api/admin/Tests";

const TestRegister = () => {
  document.title = "Register Test | Admin Panel";
  const navigate = useNavigate();
  const [Category, setCategory] = useState([]);
  const [duplicateError, setDuplicateError] = useState(""); // State for duplicate error message

  useEffect(() => {
    const getCategory = async () => {
      try {
        const CategoryData = await fetchAllTestCategories(); // Fetch categories
        setCategory(CategoryData.data); // Directly set the data array to the state
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };
    getCategory();
  }, []);

  // Success toast notification
  const notify = (message, type = "success") =>
    toast(message, {
      position: "bottom-right",
      hideProgressBar: true,
      className:
        type === "success" ? "bg-success text-white" : "bg-danger text-white",
      icon: false,
    });

  // Form validation with Formik & Yup
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      testCategory: "",
      testName: "",
      description: "", // Added description to initial values
    },
    validationSchema: Yup.object({
      testCategory: Yup.string().required("Please select a category"),
      testName: Yup.string().required("Please enter the test name"),
      description: Yup.string().required("Please enter a description"), // Added validation for description
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = {
          testCategory: values.testCategory,
          testName: values.testName.trim(),
          description: values.description.trim(), // Include description in form data
        };

        // Call the API to insert the test name into the selected category
        const response = await TestNameInsert(formData, formData.testCategory); // Use formData
        if (response.ok) {
          notify("Test Registered Successfully!"); // Show success toast
          resetForm(); // Reset form after successful submission
          setDuplicateError(""); // Clear duplicate error on success
          setTimeout(() => {
            navigate("/tests/tests-list"); // Navigate to tests list page after 5 seconds
          }, 7000); // 5000 milliseconds = 5 seconds
        } else {
          const errorData = await response.json();
          if (response.status === 409) {
            // Handle duplicate entry error
            setDuplicateError(
              "This test name already exists. Please choose a different name."
            ); // Set duplicate error message
          } else {
            // Handle other errors
            notify("This test name already exists.", "error"); // Show error toast
            setDuplicateError(""); // Clear error message for other errors
          }
        }
      } catch (error) {
        toast.error("An error occurred while adding the test.");
        console.error("Error during test insertion:", error);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Register Test"
            breadcrumbPage="Register Test"
          />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {duplicateError && (
                      <div className="alert alert-danger" role="alert">
                        {duplicateError} {/* Display duplicate error message */}
                      </div>
                    )}
                    <Row>
                      <Col md={4}>
                        <Label className="form-label">Test Category *</Label>
                        <Input
                          name="testCategory"
                          id="testCategory"
                          type="select"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.testCategory}
                          invalid={
                            validation.touched.testCategory &&
                            validation.errors.testCategory
                          }
                        >
                          <option value="">Select Category</option>
                          {Category.map((cat) => (
                            <option key={cat.hashid} value={cat.hashid}>
                              {cat.category_name}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.testCategory &&
                        validation.errors.testCategory ? (
                          <FormFeedback type="invalid">
                            {validation.errors.testCategory}
                          </FormFeedback>
                        ) : null}
                      </Col> 
                      <Col md={8}>
                        <Label className="form-label">Test Name *</Label>
                        <Input
                          name="testName"
                          id="testName"
                          type="text"
                          placeholder="Enter the test name"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.testName || ""}
                          invalid={
                            validation.touched.testName &&
                            validation.errors.testName
                          }
                        />
                        {validation.touched.testName &&
                        validation.errors.testName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.testName}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                  

                    <Row className="mt-4">
                      <Col md={12}>
                        <Label className="form-label">Description *</Label>
                        <Input
                          name="description"
                          id="description"
                          type="text"
                          placeholder="Enter a brief description"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                          }
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn">
                        Add Test
                      </Button>
                      <Link to="/tests/tests-list">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer transition={Slide} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TestRegister;
