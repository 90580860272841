import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Col,
  Row,
  Button,
  CardFooter,
} from "reactstrap";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchCounselorInfoByHashid } from "../../api/admin/Counselor";

const CounselorProfile = () => {
  document.title = "Counselor Details";

  const { hashid } = useParams();
  const [profile, setCounselorProfile] = useState({});
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  // Fetch counselor info
  useEffect(() => {
    const getCounselorInfo = async () => {
      setLoading(true);
      try {
        const counselorData = await fetchCounselorInfoByHashid(hashid);
        if (counselorData) {
          setCounselorProfile(counselorData);
        }
      } catch (error) {
        setError("Failed to fetch counselor information. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (hashid) getCounselorInfo();
  }, [hashid]);

  // Helper to view image in a new window
  const openImageInNewWindow = (url) => {
    const newWindow = window.open();
    newWindow.document.write(
      `<html><body style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;"><img src="${url}" alt="Profile" style="max-height: 90%; max-width: 90%;"/></body></html>`
    );
    newWindow.document.close();
  };

  // Render profile details
  const renderDetailRow = (label, value, isImage = false) => (
    <Row className="mb-3">
      <Col md={4}>
        <strong>{label}:</strong>
      </Col>
      <Col md={8}>
        {isImage && value ? (
          <img
            src={value}
            alt={label}
            style={{
              width: "50px",
              height: "50px",
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={() => openImageInNewWindow(value)}
          />
        ) : (
          value || "N/A"
        )}
      </Col>
    </Row>
  );

  // Loading and error handling
  if (loading) {
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Counselor Details"
            breadcrumbPage="Counselor Details"
          />
          <Row>
            <Col>
              <h5>Loading counselor profile...</h5>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Counselor Details"
            breadcrumbPage="Counselor Details"
          />
          <Row>
            <Col>
              <h5 className="text-danger">{error}</h5>
              <Link to="/counselors">
                <Button color="warning">Back</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Page"
          breadcrumbItem="Counselor Details"
          breadcrumbPage="Counselor Details"
        />
        <Row>
          <Col xs={12}>
            <Card>
              {/* <CardHeader className=" text-white">
                <h4 className="mb-0">Counselor Details</h4>
              </CardHeader> */}
              <CardBody>
                {/* Full Name (First, Middle, Last Name) */}
                <Row className="mb-3">
                  <Col md={4}>
                    <strong>Name:</strong>
                  </Col>
                  <Col md={8}>
                    {profile.first_name}{" "}
                    {profile.middle_name && profile.middle_name + " "}{" "}
                    {profile.last_name || "N/A"}
                  </Col>
                </Row>

                {renderDetailRow("Mobile Number", profile.mobile)}
                {renderDetailRow("Email", profile.email)}
                {renderDetailRow("Gender", profile.gender)}
                {renderDetailRow("Age", profile.age)}
                {/* {renderDetailRow("Marital Status", profile.marital_status)} */}
                {renderDetailRow("Occupation", profile.occupation)}
                {renderDetailRow("Education", profile.education)}
                {/* {renderDetailRow("Experience", profile.experience)} */}
                {renderDetailRow("Booking Fees", profile.price)}
                {renderDetailRow("Profile Picture", profile.profile_pic, true)}
                {renderDetailRow("Specialist In", profile.specialist)}
                {renderDetailRow("Languages", profile.languages)}
                {renderDetailRow("Description", profile.description)}
                {renderDetailRow("Heading", profile.heading)}
                {renderDetailRow("Categories", profile.categories)}

                {/* Start Date */}
                <Row className="mb-3">
                  <Col md={4}>
                    <strong>Start Date:</strong>
                  </Col>
                  <Col md={8}>
                    {profile.start_date
                      ? moment(profile.start_date).format("DD MMM YYYY")
                      : "N/A"}
                  </Col>
                </Row>

                {/* End Date */}
                <Row className="mb-3">
                  <Col md={4}>
                    <strong>End Date:</strong>
                  </Col>
                  <Col md={8}>
                    {profile.end_date
                      ? moment(profile.end_date).format("DD MMM YYYY")
                      : "N/A"}
                  </Col>
                </Row>

                <Row>
                  <CardFooter className="p-2 d-flex justify-content-end">
                    <Link to="/counselors" className="btn btn-warning">
                      Back
                    </Link>
                  </CardFooter>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CounselorProfile;
