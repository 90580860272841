import config from "../config";

export const fetchContactInquiries = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/enquiry`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};


export const deleteInquiry = async (hashid) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/enquiry/${hashid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    
    if (response.status === 200) {
      return await response.json(); // Optionally return the response
    } else {
      const results = await response.json();
      console.error(results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};


//fetch admin info

// api/adminApi.js

export const fetchAdminInfo = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const hashid = JSON.parse(storedAuthUser).hashid;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(`${config.apiBaseUrl}/admin/admin-info?hashid=${hashid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
    });

    const results = await response.json();
    if (response.status === 200) {
      return results.data; // Return the fetched admin info
    } else {
      console.error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};
