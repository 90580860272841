import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CounselorInsert } from "../../api/admin/Counselor";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const CounselorRegister = () => {
  document.title = "Register counselor | Rectifyyou ";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Add this line
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  let map = null;
  let marker = null;
  const [counselor, Insertcounselor] = useState([]);
  const [error, setError] = useState(null);
  const bottomrightnotify = () =>
    toast("Counselor registered successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
      // counselor_category: "",
      status: "",

      start_date: "",
      end_date: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Please Enter Your Email"),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Mobile Number is not valid")
        .required("Please Enter Your Mobile Number"),
      password: Yup.string().required("Please Enter Your Password"),
      // counselor_category: Yup.string().required("Please Enter Your counselor Name"),
      status: Yup.string()
        .oneOf(["active", "deactive"], "Invalid Status")
        .required("Please Select Your Status"),
      start_date: Yup.date().required("Please select a start date"),
      end_date: Yup.date()
        .required("Please select an end date")
        .min(Yup.ref("start_date"), "End date must be greater than start date"),
    }),
    // Inside your onSubmit function
onSubmit: async (values) => {
  setLoading(true); // Set loading to true when submitting
  try {
    // Format the dates to YYYY-MM-DD
    const formattedValues = {
      ...values,
      start_date: moment(values.start_date).format('YYYY-MM-DD'),
      end_date: moment(values.end_date).format('YYYY-MM-DD'),
    };

    const counselorData = await CounselorInsert(formattedValues);
    const data = await counselorData.json();
    if (counselorData.status === 200 || counselorData.status === 201) {
      bottomrightnotify();
      setTimeout(() => {
        navigate(-1);
      }, 4000);
    } else {
      console.error("Insertion failed", data.message);
      toast.error(data.message || "Failed to register counselor", {
        position: "bottom-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        icon: false
      });
      setError(counselorData.message);
    }

    Insertcounselor(counselorData);
  } catch (error) {
    console.error("An error occurred", error.message);
    toast.error(data.message || "Failed to register counselor", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
      icon: false
    });
    setError(error.message);
  } finally {
    setLoading(false); // Set loading to false after the request is complete
  }
},
  }); 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Register Counselor"
            breadcrumbPage="Register Counselor"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">First Name *</Label>
                        <Input
                          name="firstname"
                          id="firstname"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          {...(validation.touched.firstname &&
                          validation.errors.firstname
                            ? { invalid: true }
                            : {})}
                        />
                        {validation.touched.firstname &&
                          validation.errors.firstname && (
                            <FormFeedback type="invalid">
                              {validation.errors.firstname}
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Middle Name</Label>
                        <Input
                          name="middlename"
                          placeholder="Middle Name"
                          className="form-control"
                          type="text"
                          id="middlename"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.middlename || ""}
                        />
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Last Name *</Label>
                        <Input
                          name="lastname"
                          id="lastname"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          {...(validation.touched.firstname &&
                          validation.errors.firstname
                            ? { invalid: true }
                            : {})}
                        />
                        {validation.touched.lastname &&
                          validation.errors.lastname && (
                            <FormFeedback type="invalid">
                              {validation.errors.lastname}
                            </FormFeedback>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        <Label className="form-label">Mobile Number *</Label>
                        <Input
                          id="mobile"
                          name="mobile"
                          placeholder="Mobile Number"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ""}
                          invalid={
                            validation.touched.mobile &&
                            validation.errors.mobile
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mobile &&
                        validation.errors.mobile ? (
                          <FormFeedback type="invalid">
                            {validation.errors.mobile}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Email *</Label>
                        <Input
                          name="email"
                          id="email"
                          placeholder="Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Password *</Label>
                        <Input
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                    
                      <Col md={4}>
                        <Label className="form-label">Start Date</Label>
                        <Flatpickr
                          name="start_date"
                          id="start_date"
                          type="date"
                          placeholder="Start Date"
                          className={`form-control d-block ${validation.touched.start_date && validation.errors.start_date ? "is-invalid" : ""}`}
                          options={{
                            altInput: true,
                          
                            altFormat: "d/m/Y",
                            dateFormat:"Y-m-d",                          }}
                          onChange={(date) => {
                            validation.setFieldValue("start_date", date[0]);
                            // Reset end date if start date changes
                            validation.setFieldValue("end_date", null);
                          }}
                          onBlur={() =>
                            validation.setFieldTouched("start_date", true)
                          }
                          value={validation.values.start_date || ""}
                        />
                        {validation.touched.start_date &&
                        validation.errors.start_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.start_date}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={4}>
                        <Label className="form-label">End Date</Label>
                        <Flatpickr
                          name="end_date"
                          id="end_date"
                          type="date"
                          placeholder="End Date"
                          className={`form-control d-block ${validation.touched.end_date && validation.errors.end_date ? "is-invalid" : ""}`}
                          options={{
                            altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d",
                          }}
                          onChange={(date) => {
                            validation.setFieldValue("end_date", date[0]);
                          }}
                          onBlur={() =>
                            validation.setFieldTouched("end_date", true)
                          }
                          value={validation.values.end_date || ""}
                        />
                        {validation.touched.end_date &&
                        validation.errors.end_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.end_date}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={4}>
                        <Label className="form-label">Status *</Label>
                        <select
                          name="status"
                          id="status"
                          className={`form-select ${validation.touched.status && validation.errors.status ? "is-invalid" : ""}`}
                          placeholder="Status"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status}
                        >
                          <option>Select</option>
                          <option value="active">Active</option>
                          <option value="deactive">Deactive</option>
                        </select>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3"></Row>
                    <Row></Row>
                  </CardHeader>

                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2">
                    <Button
                    type="submit"
                    color="primary"
                    className="w-xs btn btn"
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                      <Link to="/counselors">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CounselorRegister;
