import React, { useEffect, useState } from "react";
import { Container, Alert } from "reactstrap";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getTestCategoryById,
  updateTestCategory,
} from "../../../api/admin/Tests";
import "react-toastify/dist/ReactToastify.css";

const CategoriesEdit = () => {
  document.title = "Edit Test Category | Rectify - Company";

  const { hashid } = useParams(); 
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const bottomrightnotify = () =>
    toast("Category updated successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        // console.log("Fetching category details for hashid:", hashid); 
        const response = await getTestCategoryById(hashid);
        const category = await response.json();

        if (response.status === 200) {
          validation.setValues({
            category_name: category.data.category_name,
          });
        } else {
          setError(category.message);
        }
      } catch (error) {
        console.error("An error occurred", error.message);
        setError(error.message);
      }
    };
    fetchCategoryDetails();
  }, [hashid]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category_name: "",
    },
    validationSchema: Yup.object({
      category_name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: async (values) => {
      try {
        const updateResponse = await updateTestCategory(hashid, values);
        const data = await updateResponse.json();

        if (updateResponse.status === 200) {
          bottomrightnotify();
          setTimeout(() => {
            navigate("/tests/test-categories");
          }, 2000);
        } else if (
          updateResponse.status === 400 &&
          data.message.includes("duplicate key value")
        ) {
          setError(
            "Category name already exists. Please choose a different name."
          );
          toast.error("Category name already exists.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            className: "bg-danger text-white",
          });
        } else {
          console.error("Update failed", data.message);
          setError(data.message); 
          toast.error(data.message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            className: "bg-danger text-white",
          });
        }
      } catch (error) {
        console.error("An error occurred", error.message);
        setError("An unexpected error occurred. Please try again later.");
        toast.error("An unexpected error occurred.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          className: "bg-danger text-white",
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-contents" style={{ marginTop: "9rem" }}>
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Edit Category"
            breadcrumbPage="Edit Test Category"
          />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={8}>
                        <Label className="form-Label">
                          Test Category Name*
                        </Label>
                        <Input
                          name="category_name"
                          id="category_name"
                          type="text"
                          className="form-control"
                          placeholder="Category Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category_name || ""}
                          invalid={
                            validation.touched.category_name &&
                            validation.errors.category_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.category_name &&
                        validation.errors.category_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.category_name}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-xs btn btn>Submit"
                      >
                        Update
                      </Button>
                      <Link to="/tests/test-categories">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoriesEdit;
