import config from "../config";

export const fetchAllCounselors = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/admin/counselors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};
export const CounselorInsert = async (values) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(`${config.apiBaseUrl}/admin/counselor-insert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Schemas: db,
    },
    body: JSON.stringify({
      first_name: values.firstname,
      middle_name: values.middlename,
      last_name: values.lastname,
      mobile: values.mobile,
      email: values.email,
      password: values.password,
      counselor_category: values.counselor_category,
      address: values.address,
      locality: values.locality,
      country: values.country,
      state: values.state,
      city: values.city,
      pincode: values.pincode,
      role: values.role,
      status: values.status,
      start_date: values.start_date, // Use the date directly
      end_date: values.end_date, // Use the date directly
    }),
  });

  return response;
};

// api call
export const CounselorDelete = async (hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(`${config.apiBaseUrl}/admin/counselor-delete/${hashid}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Schemas: db,
    },
  });

  return response;
};


export const fetchCounselorInfo = async (hashid) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const hashid = JSON.parse(storedAuthUser).hashid;
    const db = JSON.parse(storedAuthUser).db;
    const response = await fetch(
      `${config.apiBaseUrl}/counselor/counselor-info?hashid=${hashid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Schemas: db,
        },
      }
    );
    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

//view counselor-details in admin panel

export const fetchCounselorInfoByHashid = async (hashid) => {
  try {
    // Get the auth user details from localStorage (used for token and db)
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser).db;

    const response = await fetch(
      `${config.apiBaseUrl}/admin/counselor-details?hashid=${hashid}`, 
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Schemas: db,
        },
      }
    );
    const results = await response.json();
    if (response.status === 200) {
      return results.data;
    } else {
      console.error(results.message);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};



export const CounselorProfileUpdate = async (values) => {
  // console.log("Received Values for Update:", values);
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const hashid = JSON.parse(storedAuthUser).hashid;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(
    `${config.apiBaseUrl}/counselor/profile-update`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
      body: JSON.stringify({
        hashid: hashid,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        mobile: values.mobile,
        email: values.email,
        status: values.status,
        password: values.password,
        gender: values.gender,
        specialist: values.specialist,
        languages: values.languages,
        marital_status: values.marital_status,
        experience: values.experience,
        age: values.age,
        description: values.description,
        heading: values.heading,
        price: values.price,
        original_price: values.originalPrice,
        occupation: values.occupation,
        education: values.education,
        categories: values.categories,
        profile_pic: values.profile_pic,
        start_date:values.start_date,
        end_date:values.end_date,
      }),
    }
  );

  return response;
};

//fetch counselor password
export const fetchCurrentPasswordInfo = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser"); 
    const token = JSON.parse(storedAuthUser).token;
    const db = JSON.parse(storedAuthUser).db;
    const hashid = JSON.parse(storedAuthUser).hashid;
    const response = await fetch(`${config.apiBaseUrl}/counselor/getpassword?hashid=${hashid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'Schemas': db,
      }
    });
    const data = await response.json();

    if (response.status === 200) {    
      return data.data[0].password; 
    } else {       
      console.error(data.message);
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

//change counselor password
export const CounselorChangePassword= async (values) => {  
  const storedAuthUser = localStorage.getItem("authUser"); 
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;
  const hashid = JSON.parse(storedAuthUser).hashid;
  const response = await fetch(`${config.apiBaseUrl}/counselor/change_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      'Schemas': db,
    },
    body: JSON.stringify({ 
      "hashid":hashid,
      "password":values.confirm_new_password, 
    }),
  });
  return response;  
};



export const CategoriesInsert = async (values) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const hashid = JSON.parse(storedAuthUser).hashid;
  const response = await fetch(`${config.apiBaseUrl}/admin/categories-insert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      category_name: values.category_name,
      status: values.status,
    }),
  });
  return response;
};

//fetch all counselior list

// export const fetchAllCategories = async () => {
//   try {
//     const storedAuthUser = localStorage.getItem("authUser");
//     const token = JSON.parse(storedAuthUser).token;
//     const response = await fetch(`${config.apiBaseUrl}/admin/categories`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token,

//       }
//     });
//     const results = await response.json();
//     if (response.status === 200) {
//       return results.data;
//     } else {
//       console.error(results.message);
//     }
//   } catch (error) {
//     console.error('There was a problem with the fetch operation:', error);
//     throw error;
//   }
// };

export const fetchAllCategories = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/admin/categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};




export const fetchAllCategoriesList = async () => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;
    const response = await fetch(`${config.apiBaseUrl}/admin/categories-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      console.error("Error:", results.message);
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return { status: false, message: error.message };
  }
};



export const CategoriesUpdate = async (hashid, values) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const response = await fetch(`${config.apiBaseUrl}/admin/categories-update/${hashid}`, {
    method: "PUT", // Use PUT method for updates
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      category_name: values.category_name,
      status: values.status,
     
    }),
  });
  return response;
}

// Fetch category by ID for editing
export const getCategoryById = async (hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const response = await fetch(`${config.apiBaseUrl}/admin/categories/${hashid}`, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response;
};


//delet counselor category


// api call
export const CounselorCategoryDelete = async (hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(`${config.apiBaseUrl}/admin/counselorcategory-delete/${hashid}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Schemas: db,
    },
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.message || "Failed to delete category");
  }

  return response.json(); 
};

export const updateCategoriesPriority = async (priorityData) => {
  try {
    const storedAuthUser = localStorage.getItem("authUser");
    const token = JSON.parse(storedAuthUser).token;

    const response = await fetch(`${config.apiBaseUrl}/admin/categories/update-priority`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(priorityData),
    });

    const results = await response.json();

    if (response.ok) {
      return { status: true, data: results.data };
    } else {
      return { status: false, message: results.message };
    }
  } catch (error) {
    console.error("Error updating priority:", error);
    return { status: false, message: error.message };
  }
};

export const getCounselorInfo = async (hashid) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(`${config.apiBaseUrl}/counselor/counselor-info?hashid=${hashid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Schemas: db,
    },
  });
  return await response.json();
};










export const CounselorProfileUpdateAdmin = async (values ,hashid) => {
  // console.log("Received Values for Update:", values);
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(
    `${config.apiBaseUrl}/admin/counselor-update`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
      body: JSON.stringify({
        hashid: hashid,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        mobile: values.mobile,
        email: values.email,
        password:values.password,
        status: values.status,
        start_date: values.start_date,
        end_date:values.end_date,
       
      }),
    }
  );

  return response;
};


export const getAppointmentCount = async (counselorId) => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
 // const counselorId = JSON.parse(storedAuthUser).hashid;
  const db = JSON.parse(storedAuthUser).db;
 
  const response = await fetch(
    `${config.apiBaseUrl}/counselor/getAppointmentsCount?counselorId=${counselorId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
    }
  );
 
  if (!response.ok) {
    throw new Error("Error fetching appointment counts");
  }
 
  return await response.json();
};






// api/admin/Counselor.js
export const getDetailsCount = async () => {
  const storedAuthUser = localStorage.getItem("authUser");
  const token = JSON.parse(storedAuthUser).token;
  const db = JSON.parse(storedAuthUser).db;

  const response = await fetch(
    `${config.apiBaseUrl}/counselor/details-count`, // Updated endpoint
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Schemas: db,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching Details counts");
  }

  return await response.json();
};
