import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import {
  Card,
  CardHeader,
  CardFooter,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  fetchCurrentPasswordInfo,
  CounselorChangePassword,
} from "../../api/admin/Counselor";

const ChangePassword = () => {
  document.title = "Change Password | Rectify You - Company";
  const navigate = useNavigate();
  const [password, setPassword] = useState([]);
  const [passwordd, InsertPassword] = useState([]);

  useEffect(() => {
    const getPasswordInfo = async () => {
      try {
        const passwordData = await fetchCurrentPasswordInfo();
        setPassword(passwordData);
      } catch (error) {
        console.error("Failed to fetch Password:", error);
      }
    };
    getPasswordInfo();
  }, []);

  const bottomrightnotify = () =>
    toast("Change Password successfully!", {
      position: "bottom-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldpassword: password || "",
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: Yup.object({
      oldpassword: Yup.string(),
      current_password: Yup.string()
        .required("Current password is required")
        //.min(6, 'Current password must be at least 6 characters')
        .oneOf([Yup.ref("oldpassword"), null], "The entered password does not match the existing password"),
      new_password: Yup.string().required("New password is required"),
      //.min(6, 'New password must be at least 6 characters'),
      confirm_new_password: Yup.string()
        .required("Confirm password is required")
        .oneOf(
          [Yup.ref("new_password"), null],
          "New password and Confirm password must match"
        ),
    }),
    onSubmit: async (values) => {
      if (values.current_password === password) {
        const passwordData = await CounselorChangePassword(values);
        const data = await passwordData.json();
        if (passwordData.status === 200) {
          bottomrightnotify();
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          console.error("Insertion failed", data.message);
          setError(passwordData.message);
        }
        InsertPassword(passwordData);
      } else {
        // console.log("Not match");
      }
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Page"
            breadcrumbItem="Change Password"
            breadcrumbPage="Change Password"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Input
                          name="oldpassword"
                          id="oldpassword"
                          className="form-control"
                          placeholder="Current oldpassword"
                          type="hidden"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.oldpassword || ""}
                          invalid={
                            validation.touched.oldpassword &&
                            validation.errors.oldpassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.oldpassword &&
                        validation.errors.oldpassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.oldpassword}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={12}>
                        <Label className="form-label">
                          {" "}
                          Enter Current Password *{" "}
                        </Label>
                        <Input
                          name="current_password"
                          id="current_password"
                          className="form-control"
                          placeholder="Current Password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.current_password || ""}
                          invalid={
                            validation.touched.current_password &&
                            validation.errors.current_password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current_password &&
                        validation.errors.current_password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.current_password}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Label className="form-label"> New Password * </Label>
                        <Input
                          name="new_password"
                          id="new_password"
                          className="form-control"
                          placeholder="New Password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.new_password || ""}
                          invalid={
                            validation.touched.new_password &&
                            validation.errors.new_password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.new_password &&
                        validation.errors.new_password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.new_password}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={6}>
                        <Label className="form-label">
                          {" "}
                          Confirm Password *{" "}
                        </Label>
                        <Input
                          name="confirm_new_password"
                          id="confirm_new_password"
                          className="form-control"
                          placeholder="Confirm Password"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirm_new_password || ""}
                          invalid={
                            validation.touched.confirm_new_password &&
                            validation.errors.confirm_new_password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirm_new_password &&
                        validation.errors.confirm_new_password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirm_new_password}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardFooter>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-xs btn btn"
                      >
                        Submit{" "}
                      </Button>
                      <Link to="/counselor/settings">
                        <Button type="button" color="warning">
                          Back
                        </Button>
                      </Link>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
